// @ts-nocheck
import { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { setUser, updateUser } from '../../store/userSlice';
import { Button, Form, Image, Input, Spin, Upload } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { InboxOutlined, PlusOutlined } from '@ant-design/icons';
import { AuthContext } from '../../utils/authContext';
import { useLocation, NavLink, useNavigate } from 'react-router-dom';
import { NotificationContext } from '../../App';
import { FormHeader } from "../../components/auth-components/formHeader";


export const ContactUs = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
    const location = useLocation();
  const path = location.pathname;

    const user = useSelector((store: RootState) => store.user.user);
    const { openNotification } = useContext(NotificationContext);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { getUserData } = useContext(AuthContext);
    const navigate = useNavigate();
    const [form] = useForm();
    

    const handleSubmit = async () => {
        setIsLoading(true);
      
        try {
            const formData = form.getFieldsValue();
            // Filter out undefined/null/empty values
            const cleanedData = Object.fromEntries(
                Object.entries(formData).filter(([_, value]) => 
                    value !== undefined && 
                    value !== null && 
                    value !== ''
                )
            );

            const res = await fetch("/api/v1/email/contact", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(cleanedData).toString()
            });
          
            if (!res.ok) {
                const errorData = await res.json();
                throw new Error(errorData.error || 'Failed to send message');
            }

            openNotification('success', 'Message Sent!', '', 'bottomRight');
            navigate(isAuthenticated ? '/home' : '/');

        } catch (err) {
            console.error('Form submission error:', err);
            openNotification(
                'error', 
                'Error sending message', 
                err.message || 'Please try again later', 
                'bottomRight'
            );
        } finally {
            setIsLoading(false);
        }
    };

    const resetForm = () => {
        form.setFieldsValue()
    }


    useEffect(() => {

    }, [])

    const sharedInputStyle = {
        maxWidth: 512
    };

    return (
        <Spin spinning={isLoading}>
            <main className="main bg-white">
                <section className="pb-[48px]">
                    <div className="container-custom">
                        <NavLink to="/" className="flex items-center mb-3 pt-[32px]">
                            <svg className="mr-2" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M24.2561 16.4108C24.5815 16.7363 24.5815 17.2639 24.2561 17.5893L19.8453 22.0001L24.2561 26.4108C24.5815 26.7363 24.5815 27.2639 24.2561 27.5893C23.9306 27.9148 23.403 27.9148 23.0776 27.5893L18.0776 22.5893C17.7521 22.2639 17.7521 21.7363 18.0776 21.4108L23.0776 16.4108C23.403 16.0854 23.9306 16.0854 24.2561 16.4108Z" fill="#2C2E30"></path>
                            </svg>
                        </NavLink>
                        <div className="mb-[40px]">
                            <FormHeader
                                title="Leave us a note"
                                description="We'd love to hear from you"
                                showLogo={true}
                            />
                        </div>
                        <div className="account-settings-tab w-full px-6 py-3">
                            <Form
                                form={form}
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 16 }}
                                style={{}}
                                initialValues={{ ...user, organization: '' }}
                                onFinish={handleSubmit}
                                colon={false}
                            >
                                <div className="border-b-[1px] h-[1px] mb-6"></div>
                                <Form.Item
                                    label="First Name"
                                    name="first_name"
                                    rules={[{ required: true, message: 'Please input your first name!' }]}
                                >
                                    <Input placeholder="First Name" style={sharedInputStyle} />
                                </Form.Item>

                                <Form.Item
                                    label="Last Name"
                                    name="last_name"
                                    rules={[{ required: true, message: 'Please input your last name!' }]}
                                >
                                    <Input placeholder="Last Name" style={sharedInputStyle} />
                                </Form.Item>
                                <div className="border-b-[1px] h-[1px] mb-6"></div>
                                <Form.Item
                                    label="Organization Name"
                                    name="organization"
                                    style={{ position: 'relative' }}
                                // rules={[{ required: true, message: 'Please input your org name!' }]}
                                >
                                    <Input placeholder="Your Company's Name" style={sharedInputStyle} />
                                </Form.Item>
                                <div className="border-b-[1px] h-[1px] mb-6"></div>
                                {/* <Form.Item label="Add an attachment" name="attachments" valuePropName="fileList" getValueFromEvent={normFile}>
                                    <Upload.Dragger name="files" action="/upload.do" beforeUpload={() => false} style={sharedInputStyle}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click to upload or drag and drop
                                        </p>
                                        <p className="ant-upload-hint">SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                    </Upload.Dragger>
                                </Form.Item>
                                <div className="border-b-[1px] h-[1px] mb-6"></div> */}
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[{ required: true, message: 'Please input your email!' }, { type: 'email', message: 'Please input valid email!' }]}
                                >
                                    <Input disabled={isAuthenticated} placeholder="Your Email" style={sharedInputStyle} />
                                </Form.Item>
                                <div className="border-b-[1px] h-[1px] mb-6"></div>
                                <Form.Item
                                    label="How can we help?"
                                    name="text"
                                    rules={[{ required: true, message: 'Please input how we can help' }]}
                                    style={{ position: 'relative' }}
                                >
                                    <Input.TextArea rows={4} placeholder="Enter a description" style={{ ...sharedInputStyle, height: 156 }} />
                                </Form.Item>
                                <div style={{
                                    position: 'absolute',
                                    bottom: 20,
                                    right: 0,
                                    left: '50%',
                                    gap: 8,
                                    display: 'flex',
                                }}>
                                    <Button size="large" onClick={() => navigate(isAuthenticated ? '/home' : '/')}>
                                        Cancel
                                    </Button>
                                    <Button type="primary" size="large" htmlType="submit">
                                        Submit
                                    </Button>
                                </div>
                                <div className="border-b-[1px] h-[1px] mb-6"></div>
                            </Form>
                        </div>
                    </div>
                </section>
            </main>
        </Spin>
    )
}