// utils/api.ts
import axios, { AxiosInstance } from 'axios';
import { isTokenExpired, refreshCognitoToken } from './tokenUtils';

const api: AxiosInstance = axios.create({
  baseURL: `${window.location.origin}`,
  headers: {
    "Content-Type": "application/json",
  },
});

let isRefreshing = false;
let failedQueue: Array<{
  resolve: (value: any) => void;
  reject: (reason?: any) => void;
}> = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

// Request interceptor
api.interceptors.request.use(async config => {
  const tokens = JSON.parse(localStorage.getItem('tokens') || '{}');
  let accessToken = tokens.accessToken;

  if (accessToken && isTokenExpired(accessToken)) {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve: () => resolve(config), reject });
      });
    }

    isRefreshing = true;
    const { success, accessToken: newToken } = await refreshCognitoToken();
    isRefreshing = false;

    if (!success) {
      window.location.href = '/login';
      return Promise.reject('Session expired. Please login again.');
    }

    accessToken = newToken;
    processQueue(null, newToken);
  }

  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  
  return config;
});

api.interceptors.response.use(
  response => response,
  async error => {
    if (error.response?.status === 401) {
      const { success, accessToken } = await refreshCognitoToken();
      if (success && accessToken && error.config) {
        error.config.headers['Authorization'] = `Bearer ${accessToken}`;
        return axios(error.config);
      }
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default api;