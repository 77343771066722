// @ts-nocheck
import { useContext, useEffect, useState } from "react";
import { CopyIcon, CustomizeIcon, WriteIcon } from "../../components/UI/svgIcons"
import { AdditionalPopup } from "./additionalPopup"
import { CustomizePopup } from "./customizePopup"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./../../store";
import { Button, Spin } from "antd";
import { convert } from 'html-to-text';
import axios from "axios";
import { NotificationContext } from "../../App";
import { setReportConfig } from "./../../store/reportsSlice";
import api from './../../utils/api';

export const useNarrative = () => {
  const resultState = useSelector((store: RootState) => store.reports.result);
  const config = useSelector((store: RootState) => store.reports.report.config)
  const narrativeInState = useSelector((store: RootState) => store.reports.report.config.narrative)
  const org_id = useSelector((store: RootState) => store.user.user.org_id);
  const [narrativeData, setNarrativeData] = useState(narrativeInState?.html || null);
  const [isLoading, setIsLoading] = useState(false);
  const [userContextState, setUserContextState] = useState('');
  const dispatch = useDispatch()
  const updateNarrativeInConfig = (value) => {
    dispatch(setReportConfig({ ...config, narrative: { html: value, createdAt: Date.now() } }))
  }

  const fetchData = async (stateless = false, customContext = '') => {
    const {
      baseDateRange,
      comparisonDateRange,
      dateColumn,
      expectedValue,
      groupByColumns,
      fileId,
      filters,
      metricColumn,
      maxNumDimensions
    } = config.rawPayload

    setIsLoading(true)
    try {
      const response = await api.post('/api/v1/insight/file/exec-summary', {
        baseDateRange,
        comparisonDateRange,
        dateColumnType: '',
        dateColumn,
        fileId,
        filters,
        metricColumn,
        expectedValue,
        groupByColumns,
        maxNumDimensions,
        userContext: customContext || userContextState,
        org_id: org_id || undefined,
      });
      const data = response.data.toString().replace('body {', "bodyBlockedStyle {");

      if (!stateless) {
        setNarrativeData(data);
        updateNarrativeInConfig(data);
        setIsLoading(false)
      } else {
        setIsLoading(false)
        return data;
      }

    } catch (e) {
      console.log(e)
      setIsLoading(false);
    }
  }
  return ({
    isLoading,
    narrativeData,
    setUserContextState,
    userContextState,
    setNarrativeData: (value) => {
      setNarrativeData(value);
      updateNarrativeInConfig(value);
    },
    narrativeInState,
    fetchData,
  })
}

export const NarrativeTab = ({ isShown, deepFilters, narrativeControl, showAllTabs }) => {
  const [isAdditionalPopupOpen, setIsAdditionalPopupOpen] = useState(false);
  const [isCustomizePopupOpen, setIsCustomizePopupOpen] = useState(false);
  const [isPlainText, setIsPlainText] = useState(false);
  const { openNotification } = useContext(NotificationContext);

  const {
    isLoading,
    narrativeData,
    setUserContextState,
    fetchData,
    userContextState,
    setNarrativeData,
    narrativeInState,
  } = narrativeControl;

  const text = convert(narrativeData || '')

  // console.log(userContextState)
  console.log('Executing narrativeTab.tsx')

  const copyText = async () => {
    await navigator.clipboard.writeText(text);
    openNotification('success', 'Copied!', '', 'bottomRight')
  }

  if (!isShown) return null;
  return (
    <>
      {/* <!--Tabs content--> */}
      <div className="w-full accent-block border border-[#E6E6E7] p-6 max-w-[1088px] mx-auto">
        <div className="flex items-center justify-between mb-6">
          <div className="">
            <div className="font-semibold text-[#1D2E54] leading-[130%] text-[20px] mb-2">Executive Summary</div>
            <div className="text-[#35383B] leading-[150%] text-[16px]">{narrativeInState?.createdAt && `${new Date(narrativeInState?.createdAt).toLocaleDateString()}`}</div>
            {/* <div className="text-[#FF0000] leading-[150%] text-[14px] font-medium">The summary generation is currently down. We are working on a fix.</div> */}
          </div>
          <div className="flex items-center gap-4">
            <button onClick={() => setIsAdditionalPopupOpen(true)} className="flex items-center justify-center h-10 w-10" type="button" id="edit">
              <WriteIcon iconClass="hover:stroke-[#1D2E54] stroke-[#445082]" />
            </button>
            <button onClick={() => setIsCustomizePopupOpen(true)} className="flex items-center justify-center h-10 w-10" type="button" id="customize">
              <CustomizeIcon iconClass="hover:stroke-[#1D2E54] stroke-[#445082]" />
            </button>
            <button className="flex items-center justify-center h-10 w-10" type="button" id="copy" onClick={copyText}>
              <CopyIcon iconClass="hover:stroke-[#1D2E54] stroke-[#445082]" />
            </button>
          </div>
        </div>
        <Spin spinning={isLoading}>
          <div className="w-full"
            style={{
              lineHeight: 1.6,
              whiteSpace: !narrativeData?.includes('<html>') ? 'pre-wrap' : '',
            }}
            dangerouslySetInnerHTML={{ __html: narrativeData }}>

          </div>
        </Spin>
      </div>
      <div className="flex items-center justify-center mb-6 mt-6">
        {!showAllTabs && (<Button style={{
          height: 44,
          paddingBottom: 7,
        }} className="accent-btn" type="primary" disabled={isLoading} onClick={() => {
          fetchData()
          setIsPlainText(false)
        }}>Regenerate</Button>
        )}
      </div>



      {/* <!-- Additional Context popup --> */}
      <AdditionalPopup isOpen={isAdditionalPopupOpen} onClose={() => setIsAdditionalPopupOpen(false)} setUserContextState={setUserContextState} userContextState={userContextState} onSubmit={() => {
        fetchData();
        setIsPlainText(false)
        setIsAdditionalPopupOpen(false);
      }} />

      {/* <!-- Customize popup --> */}
      <CustomizePopup isOpen={isCustomizePopupOpen} userContextState={userContextState} narrativeData={narrativeData} fetchData={fetchData} setNarrativeData={(v) => {
        setNarrativeData(v);
        setIsPlainText(true);
      }} onClose={() => setIsCustomizePopupOpen(false)} />
    </>
  )
}