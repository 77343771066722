export interface AwsConfig {
  userPoolId: string;
  clientId: string;
  GRAPHQL_BASE_URL: string;
  GRAPHQL_API_KEY: string;
  region: string;
}

const configs = {
  development: {
    userPoolId: 'us-west-1_qPnLD8LBH',
    clientId: '34rm0dco94hd8nn9q6oqnfosef',
    GRAPHQL_BASE_URL: 'https://plw9trxm44.execute-api.us-east-1.amazonaws.com/graphql',
    GRAPHQL_API_KEY: 'WETrGhFivOdFLx91axtaDERWdG81Ck4GrWfauUJ1dNxE0BreAdWqqp9nJSTqmgff',
    region: 'us-west-1'
  },
  production: {
    userPoolId: 'us-west-1_tStvIiZ2C',
    clientId: '7hmdm55ho83543cm7gdc2dajps',
    GRAPHQL_BASE_URL: 'https://83pd73g30k.execute-api.us-west-1.amazonaws.com/graphql',
    GRAPHQL_API_KEY: 'WETrGhFivOdFLx91axtaDERWdG81Ck4GrWfauUJ1dNxE0BreAdWqqp9nJSTqmgff',
    region: 'us-west-1'
  }
} as const;

const isProd = () => {
  const prodDomains = ['analyticsbot.ai', 'www.analyticsbot.ai', 'app.analyticsbot.ai'];
  return prodDomains.includes(window.location.hostname);
};

export const awsConfig: AwsConfig = configs[isProd() ? 'production' : 'development'];