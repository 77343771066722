// @ts-nocheck
import { useContext, useEffect, useRef, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  CloseIcon,
  ConfigIcon,
  CrossIcon,
  DownloadIcon,
  DriversIcon,
  FullLogoIcon,
  NarrativeIcon,
  ReportSearchIcon,
  SavedIcon,
  SegmentsIcon,
  ShareIcon,
  WaterfallIcon,
} from "../../components/UI/svgIcons";
import { Filter, useFilterForm } from "./filter";
import { SharePopup } from "./sharePopup";
import { SavedPopup } from "./savedPopup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import axios from "axios";
import { AuthContext } from "../../utils/authContext";
import { Tooltip, Spin, Drawer, Button, Popconfirm } from "antd";
import { OverviewTab } from "./overviewTab";
import { DriversTab } from "./driversTab";
import { setResult, setReport, setReportConfig, setReportName as setReportNameAction } from "../../store/reportsSlice";
import { NarrativeTab, useNarrative } from "./narrativeTab";
import { WaterfallTab, useWaterfall } from "./waterfallTab";
import { SegmentDetails } from "./segmentDetails";
import { NotificationContext } from "../../App";
import { BackToTopButton } from "./backToTopButton";
import api from '../../utils/api';

export enum DiscoverTabEnum {
  overview = "overview",
  drivers = "drivers",
  segments = "segments",
  waterfall = "waterfall",
  narrative = "narrative"
}

export const DiscoverSegments = ({ toPDF, setIsLoadingPdf }) => {
  const [drawerSlice, setDrawerSlice] = useState();
  const [activeTab, setActiveTab] = useState(DiscoverTabEnum.overview);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);
  const [isSavedPopupOpen, setIsSavedPopupOpen] = useState(false);
  const [showAllTabs, setIsShowAllTabs] = useState(false);
  const { getUserData } = useContext(AuthContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const resultState = useSelector((store: RootState) => store.reports.result)
  const reports = useSelector((store: RootState) => store.reports.reports)

  const waterfallProp = useWaterfall();
  const narrativeControl = useNarrative();

  const fetchTabs = () => {
    narrativeControl.fetchData()
    waterfallProp.fetchWaterfall();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const formControl = useFilterForm({
    onSent: () => {
      setIsOpen(false)
      // fetchTabs()
    }
  });


  useEffect(() => {
    setIsLoading(!!formControl.isLoading);
  }, [formControl.isLoading])

  const filters = useSelector((store: RootState) => store.reports.report.config.settings);

  // const { hash, search,  } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const openedReportKey = searchParams.get("reportId")

  const handleOpenedReportKey = async (openedReportKeyArg) => {
    console.log('searching', openedReportKeyArg)
    if (openedReportKeyArg) {
      console.log('searching2', openedReportKeyArg)
      setIsLoading(true)
      const report = reports.find(report => report.report_id === Number(openedReportKeyArg))
      console.log(report)
      if (report && !Object.keys(resultState || {}).length) {
        const reportCfg = JSON.parse(report.report_config)
        dispatch(setReportConfig(reportCfg))
        dispatch(setReportNameAction(report.report_name))
        await formControl.sendData(reportCfg.rawPayload, reportCfg)
      } else if (!Object.keys(resultState || {}).length) {
        // fetch report by id
        const fetchedReport = await api.get(`/api/v1/reports/${openedReportKeyArg}`);
        const reportCfg = JSON.parse(fetchedReport.data.report_config)
        dispatch(setReportConfig(reportCfg))
        dispatch(setReportNameAction(fetchedReport.data.report_name))
        await formControl.sendData(reportCfg.rawPayload, reportCfg)
      } else {
      }
      setIsLoading(false)
    } else {
      // handle not found

    }
  }

  useEffect(() => {
    // load report from url
    handleOpenedReportKey(openedReportKey)
  }, [openedReportKey])

  // if (Object.keys(wholeReportState).length && Object.keys(wholeResultState).length) {
  //   localStorage.setItem('reportResultCache', JSON.stringify({
  //     result: wholeResultState,
  //     report: wholeReportState,
  //   }));
  // } else if (localStorage.getItem('reportResultCache')) {
  //   const cachedValues = JSON.parse(localStorage.getItem('reportResultCache') || '');
  //   dispatch(setReport(cachedValues.report))
  //   dispatch(setResult(cachedValues.result))
  // }

  const deepFilters = Object.keys(filters || {}).length ? JSON.stringify(filters) : undefined;

  const { openNotification, notificationApi } = useContext(NotificationContext);

  const getTabClass = (tabType) => {
    const baseClass = "flex items-center font-medium text-[14px] border-b-2 duration-300 ease-in-out justify-center leading-[140%] p-4 rounded-t-xl w-[188px]";
    const activeClass = "bg-[#FFFFFF] border-b-[#7F8ACA] text-[#2B3C6B]";
    const inactiveClass = "border-b-[#E6E6E7] hover:bg-[#FFFFFF] hover:border-[#7F8ACA] text-[#828385]";

    return `${baseClass} ${activeTab === tabType ? activeClass : inactiveClass}`;
  };

  const config = useSelector((store: RootState) => store.reports.report.config)
  const prevReportName = useSelector((store: RootState) => store.reports.report_name)
  // @ts-ignore
  const [reportName, setReportName] = useState(prevReportName || 'New Analysis');

  const user = useSelector((store: RootState) => store.user.user)

  const handleTabClick = (tab: DiscoverTabEnum) => {
    // navigate('#');
    setActiveTab(tab);
  };

  const onSaveClick = async (reportNameArg) => {
    setIsLoading(true)
    const report_name = reportNameArg || reportName || "Analyze Growth Segments"
    const payload = {
      "report_name": report_name,
      // @ts-ignore
      "owner_id": user.user_id,
      // "report_content": JSON.stringify(resultState),
      "report_config": JSON.stringify({ ...config, report_name }),
      "organization": "FIX ME, MUST BE NOT NECESSARY",
      // @ts-ignore
      // ...(user.org_id ? ({"organization": user.org_id}) : {}),
    }
    try {
      const res = await api.post('/api/v1/reports/', payload);
      setIsLoading(false)
      setIsSavedPopupOpen(false)
      await getUserData();
      navigate(`/discover-segments?reportId=${res.data.report_id}`);
    } catch (e) {
      setIsLoading(false)
      openNotification('error', 'Report with the same name already exists in the organization', '', 'bottomRight')
      // notificationApi.error({
      //   message: 'Error',
      //   description: 'Report with the same name already exists in the organization',
      //   placement: 'bottomRight',
      //   btn: (
      //     <Button type="primary" size="small" onClick={() => api.destroy(key)}>
      //     Confirm
      //   </Button>
      //   )
      // })
      console.log(e)
    }
  }

  const onSaveReplaceClick = async (report, reportNameArg) => {
    setIsLoading(true)
    const report_name = reportNameArg || reportName || "Analyze Growth Segments"
    const payload = {
      "report_name": report_name,
      // @ts-ignore
      "owner_id": user.user_id,
      // "report_content": JSON.stringify(result),
      "report_config": JSON.stringify({ ...config, report_name }),
      "organization": "FIX ME, MUST BE NOT NECESSARY",
      // @ts-ignore
      // ...(user.org_id ? ({"organization": user.org_id}) : {}),
    }
    try {
      const res = await api.put(`/api/v1/reports/${report?.report_id}`, payload);
      setIsLoading(false)
      setIsSavedPopupOpen(false)
      await getUserData();
      navigate(`/discover-segments?reportId=${res.data.report_id}`);
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }

  const togglePanel = () => {
    setIsOpen(!isOpen);
  };

  const downloadPages = async () => {
    setTimeout(() => {
      toPDF()
      setIsShowAllTabs(false)
    }, 1000)

    // const element = document.body;
    // const canvas = await html2canvas(element);
    // const imgData = canvas.toDataURL("image/png");
    // const pdf = new jsPDF("p", "mm", "a4");

    // const imgWidth = 210;
    // const pageHeight = 295;
    // const imgHeight = (canvas.height * imgWidth) / canvas.width;
    // let heightLeft = imgHeight;

    // let position = 0;

    // pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    // heightLeft -= pageHeight;

    // while (heightLeft >= 0) {
    //   position = heightLeft - imgHeight;
    //   pdf.addPage();
    //   pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    //   heightLeft -= pageHeight;
    // }

    // pdf.save("report.pdf");

  }

  const handleDownload = async () => {
    setDrawerSlice();
    // navigate('#');
    setIsShowAllTabs(true)
  };

  useEffect(() => {
    if (showAllTabs) {
      setIsLoadingPdf(true)
      setTimeout(() => {
        onClose();
        downloadPages()
      }, 1000)

    }
  }, [showAllTabs])

  const tabNames = {
    [DiscoverTabEnum.overview]: "Overview",
    [DiscoverTabEnum.segments]: "Segments",
    [DiscoverTabEnum.drivers]: "Drivers",
    [DiscoverTabEnum.narrative]: "Narrative",
    // Add other tabs as needed
  };

  const pageRef = useRef(null)

  const goToOverview = () => {
    handleTabClick(DiscoverTabEnum.overview)

  }

  const showDrawer = (slice) => () => {
    // navigate(`#${slice.serializedKey}`);
    setDrawerSlice(slice);
  };

  const onClose = () => {
    setDrawerSlice();
    // navigate('#');
  };

  // const { hash } = useLocation();

  // const openedSliceKey = hash && hash.length > 1 ? decodeURI(hash.slice(1)) : ''

  // useEffect(() => {
  //   if (openedSliceKey && !drawerSlice && isShown && !hideDrawer) {
  //     setDrawerSlice(Object.values(result.dimensionSliceInfo).find((slice) => slice.serializedKey === openedSliceKey))
  //   }
  // }, [openedSliceKey])


  const drawer = (
    <Drawer width={"100%"} mask={false}
      placement="left"
      closeIcon={false}
      close
      // extra={

      // }
      style={{
        top: 175,
        position: 'absolute',
        height: '-webkit-fill-available',
      }} onClose={onClose} open={drawerSlice}
      styles={{
        header: {
          display: 'flex',
          flexDirection: 'row-reverse',
        },
        body: {
          // overflow: 'unset',
        }
      }}
      rootStyle={{
        zIndex: 1000,
      }}
      title={(
        <div className="container-custom">
          <div className="flex justify-between items-center">
            <div className="flex gap-3">
              <h1 className="font-semibold text-[#1D2E54] text-[32px] mb-0">
                Segment Detail
              </h1>
              <div className="flex gap-1 items-center">
                {drawerSlice?.key?.map?.((item, i) => <>{i !== 0 && ' and '}
                  <span className="font-medium h-min px-2 py-[2px] bg-[#F3F2FF] inline-block rounded-2xl text-[#2B3C6B] text-[12px] mx-[2px]">
                    {item.dimension} = {item.value}
                  </span>
                </>)}
              </div>
            </div>
            <Button size="large" onClick={onClose}>Close Details</Button>
          </div>
          <div className="">
          </div>
        </div>
      )}>
      <SegmentDetails slice={drawerSlice} />
    </Drawer>
  )



  useEffect(() => {
    if (deepFilters && config.rawPayload && Object.keys(resultState || {}).length) {
      fetchTabs()
    }
  }, [deepFilters, Object.keys(resultState || {}).length])

  const narrativeTab = (
    <NarrativeTab showAllTabs={showAllTabs} narrativeControl={narrativeControl} deepFilters={deepFilters} isShown={(activeTab === DiscoverTabEnum.narrative || showAllTabs)} pageRef={pageRef} segments formControl={formControl} />
  )

  if (!Object.keys(resultState || {}).length) {
    if (!openedReportKey) {
      console.log("NO OPENED REPORT KEY")
      navigate('/home')
    }
    return <Spin spinning={true}><main className="main h-[90vh]"></main></Spin>;
  }

  return (
    <>
      <Spin spinning={isLoading}>
        <main className="main">
          <section className="pt-[32px]">
            {!showAllTabs && (
              <div className="container-custom">
                {/* <!--Tabs navigation--> */}
                <ul
                  className="flex border-b-0 flex-row flex-wrap list-none ps-0 segments-tabs"
                  role="tablist"
                  data-twe-nav-ref
                >
                  <li>
                    <button onClick={() => handleTabClick(DiscoverTabEnum.overview)} className={getTabClass(DiscoverTabEnum.overview)}>
                      <ReportSearchIcon iconClass="mr-2" />
                      Overview
                    </button>
                  </li>
                  <li role="presentation">
                    <button onClick={() => handleTabClick(DiscoverTabEnum.segments)} className={getTabClass(DiscoverTabEnum.segments)}>
                      <SegmentsIcon iconClass="mr-2" />
                      Segments
                    </button>
                  </li>
                  <li>
                    <button onClick={() => handleTabClick(DiscoverTabEnum.drivers)} className={getTabClass(DiscoverTabEnum.drivers)}>
                      <DriversIcon iconClass="mr-2" />
                      Drivers
                    </button>
                  </li>
                  <li role="presentation">
                    <button onClick={() => handleTabClick(DiscoverTabEnum.waterfall)} className={getTabClass(DiscoverTabEnum.waterfall)}>
                      <WaterfallIcon iconClass="mr-2" />
                      Waterfall
                    </button>
                  </li>
                  <li role="presentation">
                    <button onClick={() => handleTabClick(DiscoverTabEnum.narrative)} className={getTabClass(DiscoverTabEnum.narrative)}>
                      <NarrativeIcon iconClass="mr-2" />
                      Exec Summary
                    </button>
                  </li>
                </ul>
              </div>
            )}
            <div ref={pageRef} className="min-h-[70vh] bg-[#ffffff] py-10">
              <div className="container-custom">
                {/* <!--Tab header--> */}
                {!showAllTabs && (
                  <div className="flex items-center justify-between relative mb-10" style={{
                    zIndex: 10,
                    position: 'relative'
                  }}>
                    <h1 className="font-semibold text-[#1D2E54] text-[32px]">
                      {tabNames[activeTab]}
                    </h1>
                    <div className="flex items-center gap-4">
                      <Tooltip title="Analysis Configuration">
                        <button
                          onClick={togglePanel}
                          className="flex items-center justify-center duration-300 ease-in-out bg-[#F3F2FF] h-[44px] hover:bg-[#D5D6FD] rounded-xl w-[44px]"
                          type="button"
                          id="settings-btn"
                        >
                          {isOpen ? <CloseIcon /> : <ConfigIcon />}
                        </button>
                      </Tooltip>

                      <Tooltip title={narrativeControl.isLoading ? 'Narrative is being generated, please wait...' : "Download"}>
                        <Spin spinning={showAllTabs || narrativeControl.isLoading}>
                          <button
                            onClick={handleDownload}
                            className="flex items-center justify-center duration-300 ease-in-out bg-[#F3F2FF] h-[44px] hover:bg-[#D5D6FD] rounded-xl w-[44px]"
                            type="button"
                            id="download-btn"
                          >
                            <DownloadIcon />
                          </button>
                        </Spin>
                      </Tooltip>

                      <Tooltip title={openedReportKey ? "Share" : "You need to save report before sharing it"}>
                        <Popconfirm
                          okText="Save"
                          cancelText="Cancel"
                          title="You need to save report before sharing it"
                          onConfirm={() => setIsSavedPopupOpen(true)}
                          disabled={openedReportKey}>
                          <button
                            onClick={openedReportKey ? () => setIsSharePopupOpen(true) : null}
                            className="flex items-center justify-center duration-300 ease-in-out bg-[#F3F2FF] h-[44px] hover:bg-[#D5D6FD] rounded-xl w-[44px]"
                            type="button"
                            id="share-btn"
                          >
                            <ShareIcon />
                          </button>
                        </Popconfirm>
                      </Tooltip>

                      <Tooltip title="Save">
                        <button
                          onClick={() => setIsSavedPopupOpen(true)}
                          className="flex items-center justify-center duration-300 ease-in-out bg-[#F3F2FF] h-[44px] hover:bg-[#D5D6FD] rounded-xl w-[44px]"
                          type="button"
                          id="save-btn"
                        >
                          <SavedIcon />
                        </button>
                      </Tooltip>

                      <Link
                        to="/home"
                        className="flex items-center justify-center p-[10px] w-[156px] bg-[#445082] text-[16px] rounded-[12px] hover:bg-[#1677ff] text-white hover:text-white !important"
                        id="new-report"
                      >
                        <CrossIcon iconClass="mr-2" />
                        New Analysis
                      </Link>


                    </div>
                    <div
                      id="settings-panel"
                      className={`flex ${isOpen ? "" : "hidden"
                        } w-full absolute bg-[#F9FBFD] border border-[#CACBCB] gap-4 p-4 rounded-xl shadow-md top-[52px] z-[1]`}
                    >
                      <Filter formControl={formControl} onClose={() => setIsOpen(false)} />
                    </div>
                  </div>
                )}
                {showAllTabs && (
                  <div className="w-full py-6 mb-10 flex justify-center items-center gap-1">
                    <Link to="/home" className="">
                      <FullLogoIcon />
                    </Link>
                  </div>
                )}
                {showAllTabs && (<div className="w-full py-6 mb-10 font-semibold text-[#1D2E54] leading-[130%] text-[20px] flex justify-center items-center gap-1">
                  Prepared for {user.first_name} {user.last_name}
                </div>)}
                {showAllTabs && narrativeTab}
                {showAllTabs && (<div className="w-full py-6 mb-10 font-semibold text-[#1D2E54] leading-[130%] text-[20px] flex justify-center items-center gap-1">
                  {/* Explore more at <a href={window.location.origin}>{window.location.origin}</a> */}
                </div>)}
                <OverviewTab isShown={(activeTab === DiscoverTabEnum.overview || showAllTabs)} />
                <DriversTab deepFilters={deepFilters} showDrawer={showDrawer} onClose={onClose} drawerSlice={drawerSlice} setDrawerSlice={setDrawerSlice} isShown={(activeTab === DiscoverTabEnum.drivers || showAllTabs)} hideDrawer={showAllTabs} pageRef={pageRef} formControl={formControl} goToOverview={goToOverview} />
                <DriversTab deepFilters={deepFilters} showDrawer={showDrawer} onClose={onClose} drawerSlice={drawerSlice} setDrawerSlice={setDrawerSlice} isShown={(activeTab === DiscoverTabEnum.segments || showAllTabs)} hideDrawer={showAllTabs} pageRef={pageRef} segments formControl={formControl} goToOverview={goToOverview} />
                <WaterfallTab showAllTabs={showAllTabs} waterfallProp={waterfallProp} deepFilters={deepFilters} isShown={(activeTab === DiscoverTabEnum.waterfall || showAllTabs)} pageRef={pageRef} segments formControl={formControl} goToOverview={goToOverview} />
                {!showAllTabs && narrativeTab}
                {showAllTabs && (<div className="w-full py-6 mb-10 font-semibold text-[#1D2E54] leading-[130%] text-[20px] flex justify-center items-center gap-1">
                  You can explore this analysis more in <a href={window.location.href}>{' '}DataScoop:{' '}</a>{' '} <a href={window.location.href}>{window.location.href}</a>
                </div>)}
                {drawer}
              </div>
            </div>
          </section>
        </main>

        <SharePopup isOpen={isSharePopupOpen} onClose={() => setIsSharePopupOpen(false)} />
        <SavedPopup
          isOpen={isSavedPopupOpen}
          onSaveReplaceClick={onSaveReplaceClick}
          onSaveClick={onSaveClick}
          onClose={() => setIsSavedPopupOpen(false)}
          setReportName={setReportName}
          reportName={reportName}
        />
      </Spin>
      <BackToTopButton />
    </>
  );
};
