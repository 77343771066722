// @ts-nocheck
import React from 'react';
import { NavLink } from 'react-router-dom';
import { FormHeader } from "../../components/auth-components/formHeader";

export const BookingEmbed = () => {
  return (
    <main className="main bg-white">
      <section className="pb-[48px] px-[24px] max-w-4xl mx-auto">
        <div className="container-custom">
          {/* Back Navigation */}
          <NavLink to="/" className="flex items-center mb-3 pt-[32px]">
            <svg
              className="mr-2"
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.2561 16.4108C24.5815 16.7363 24.5815 17.2639 24.2561 17.5893L19.8453 22.0001L24.2561 26.4108C24.5815 26.7363 24.5815 27.2639 24.2561 27.5893C23.9306 27.9148 23.403 27.9148 23.0776 27.5893L18.0776 22.5893C17.7521 22.2639 17.7521 21.7363 18.0776 21.4108L23.0776 16.4108C23.403 16.0854 23.9306 16.0854 24.2561 16.4108Z"
                fill="#2C2E30"
              ></path>
            </svg>
          </NavLink>

          {/* Form Header */}
          <div className="mb-[80px]">
            <FormHeader
              title="Book a Demo"
              description="We look forward to helping you"
              showLogo={true}
            />
          </div>

          {/* Iframe */}
          <div className="iframe-container">
            <iframe
              width="100%"
              height="750px"
              src="https://datascoop.zohobookings.com/portal-embed#/demo"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </section>
    </main>
  );
};

export default BookingEmbed;