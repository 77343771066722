// @ts-nocheck
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setReportConfig, setResult, setResultTime } from "../../store/reportsSlice";
import { ReportSetupTab } from "../../modules/report-setup/reportSetupTab";
import { Button, Spin } from "antd";
import dayjs from "dayjs";
import { useOnClickOutside } from "usehooks-ts";
import { useForm } from "antd/es/form/Form";
import { RootState } from "./../../store";
import { NotificationContext } from "../../App";
import api from '../../utils/api';

export const useFilterForm = ({
  onSent
}) => {
  const [newConfig, setNewConfig] = useState({});
  const [payload, setPayload] = useState({ isError: true });
  const [resetCounter, setResetCounter] = useState(0);
  const [updateCounter, setUpdateCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = useForm();
  const filters = useSelector((store: RootState) => store.reports.report.config.settings);
  const config = useSelector((store: RootState) => store.reports.report.config);
  const isRatio = filters.selectedMetricType === 'ratio';
  const { openNotification } = useContext(NotificationContext);
  const dispatch = useDispatch();
  const [objectiveText, setObjectiveText] = useState(config?.objectiveText || '');

  const pushFilters = (filters, cb = () => { }) => {
    setIsLoading(true)
    // console.log('pushing', filters)
    if (!isRatio) {
      const oldFilters = form.getFieldValue('filters') || [];
      form.setFieldValue('filters', [...oldFilters, ...filters.map(item => ({
        column: item.dimension,
        operator: item.value !== 'None' ? 'eq' : 'empty',
        columnsToCompare: [item.value !== 'None' ? item.value : null],
      }))])
    } else {
      const oldFiltersNum = form.getFieldValue('ratio').numFilters || [];
      const oldFiltersDen = form.getFieldValue('ratio').denFilters || [];
      form.setFieldValue(['ratio', 'numFilters'], [...oldFiltersNum, ...filters.map(item => ({
        column: item.dimension,
        operator: 'eq',
        columnsToCompare: [item.value],
      }))])
      form.setFieldValue(['ratio', 'denFilters'], [...oldFiltersDen, ...filters.map(item => ({
        column: item.dimension,
        operator: 'eq',
        columnsToCompare: [item.value],
      }))])
    }

    setTimeout(() => {
      setUpdateCounter(old => old + 1)
      cb();
      setIsLoading(false)
    }, 500)
  };

  const sendData = async (payloadArg = payload, configArg = newConfig) => {
    setIsLoading(true)
    const startTimestamp = dayjs.utc()

    try {
      await form.validateFields();
      const response = await api.post('/api/v1/insight/file/metric', payloadArg);
      const data = response.data;
      const endTimestamp = dayjs.utc()
      console.log('timestart set')
      dispatch(setResultTime(endTimestamp.valueOf() - startTimestamp.valueOf()))
      console.log('time set')
      dispatch(setReportConfig({ ...configArg, rawPayload: payloadArg, objectiveText }))
      console.log('config set')
      setIsLoading(false)
      dispatch(setResult(data))
      console.log('result set')
      if (onSent) onSent();
    } catch (e) {
      if (e?.response?.data?.error === "EMPTY_DATASET") {
        openNotification('error', 'Your filters are too restrictive to produce any data for analysis', '', 'bottomRight')
      } else if (e?.response?.data?.error) {
        openNotification('error', e?.response?.data?.error, '', 'bottomRight')
      } else {
        console.log(e)
        if(e?.errorFields?.length){
          form.scrollToField(e?.errorFields?.[e?.errorFields?.length - 1].name, { block: 'end'})
        }
      }
      setIsLoading(false);
    }

  }

  const updatePayload = (newReport, newConfig, forceSend = false) => {
    // console.log('newReport, newConfig)', newReport, newConfig)
    setPayload(newReport);
    setNewConfig(newConfig);
    if (forceSend) {
      sendData(newReport, newConfig)
    }
  };

  useEffect(() => {
    setObjectiveText(config?.objectiveText || '')
  }, [config?.objectiveText])

  const resetForm = () => {
    setResetCounter(old => old + 1)
  }
  return ({
    updatePayload,
    form,
    resetForm,
    sendData,
    resetCounter,
    updateCounter,
    isLoading,
    payload,
    pushFilters,
    objectiveText,
    setObjectiveText,
  })
}

// @ts-ignore
export const Filter = ({ formControl, onClose }: { formControl: any, onClose: any }) => {
  const modalRef = useRef(null)
  useOnClickOutside(modalRef, (event) => {
    if (!isLoading) {
      const root = document.getElementById('root')
      // @ts-ignore
      const isExcluded = !root?.contains(event.target);
      if (!isExcluded) {
        onClose()
      }
    }
  })

  const {
    updatePayload,
    form,
    resetForm,
    sendData,
    resetCounter,
    updateCounter,
    isLoading,
    payload,
  } = formControl

  const filterSpin = {
    width: '100%',
  };

  return (
    <>
      {/* <!-- Panel's columns --> */}

      <div className="flex w-full gap-5 flex-col items-center" ref={modalRef}>
        <div className="flex items-center gap-2">
          <Button onClick={resetForm} disabled={isLoading}>Reset</Button>
          <Button onClick={() => sendData()} disabled={payload?.isError || isLoading} type="primary">Rerun</Button>
        </div>
          <ReportSetupTab updatePayload={updatePayload} resetCounter={resetCounter} form={form} updateCounter={updateCounter} isFilterModal />
        <Spin spinning={isLoading} wrapperClassName="min-w-[900px]" fullscreen />
        <div className="flex items-center gap-2">
          <Button onClick={resetForm} disabled={isLoading}>Reset</Button>
          <Button onClick={() => sendData()} disabled={payload?.isError || isLoading} type="primary">Rerun</Button>
        </div>
      </div>

    </>
  );
};
