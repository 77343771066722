import { Link } from "react-router-dom"

export const Footer = () => {
    return (
        <footer className="lg:py-[48px] py-[36px]">
            <div className="container-custom">
                <div className="text-center mb-[64px]">
                    <h2 className="font-semibold text-center leading-[120%] text-[#1D2E54] text-[24px] lg:text-[32px] lg:mb-4 mb-3">Experience DataScoop with Your Own Data</h2>
                    <div className="leading-[150%] text-center text-[14px] text-[#45484C] lg:text-[18px] lg:mb-4 mb-3">Once you have seen an unexpected insight, you'd never want to miss another</div>
                    <div className="flex gap-3 flex-col lg:flex-row lg:max-w-[unset] max-w-[344px] mx-auto justify-center w-full">
                        {/* <Link to="/contact-us" className="font-semibold text-[16px] text-center duration-300 ease-in-out h-[44px] rounded-xl border border-[#CACBCB] hover:bg-[#F3F2FF] hover:border-[#AAABAC] text-[#2C2E30] leading-[130%] px-[20px] py-[12px]" type="button">
                            Book a Demo
                        </Link> */}
                        {/* <div>or</div> */}
                        {/* <Link to="/register" className="font-semibold text-[16px] text-center duration-300 ease-in-out h-[44px] rounded-xl bg-[#445082] hover:bg-[#2b3c6b] text-[#ffffff] leading-[130%] px-[20px] py-[12px]" type="button">
                            Get started
                        </Link> */}
                        <Link to="/book" className="font-semibold text-[16px] text-center duration-300 ease-in-out h-[44px] rounded-xl bg-[#445082] hover:bg-[#2b3c6b] text-[#ffffff] leading-[130%] px-[20px] py-[12px]" type="button">
                            Book a Demo with Founder
                        </Link>
                    </div>
                </div>
                <div className="flex items-center flex-col lg:flex-row gap-[20px] border-t border-t-[#E6E6E7] justify-between lg:pt-[32px] pt-[40px]">
                    <div className="leading-[150%] text-[16px] text-[#828385] text-center">
                        Copyright © 2024
                        <span className="font-semibold text-[#2B3C6B] text-[16px] ml-[8px] mr-[8px]">
                            Predpect, Inc.
                        </span>
                        All rights reserved.
                    </div>
                    <div className="flex items-center gap-4">
                    <Link
                            to="/terms"
                            className="leading-[150%] text-[16px] text-[#828385] duration-300 ease-in-out hover:text-[#2B3C6B]"
                        >
                            Terms
                        </Link>
                        <Link
                            to="/privacy"
                            className="leading-[150%] text-[16px] text-[#828385] duration-300 ease-in-out hover:text-[#2B3C6B]"
                        >
                            Privacy
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    )
}