// @ts-nocheck
import { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { setUser } from '../../store/userSlice';
import { Button, Form, Input, Spin, Upload } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { PlusOutlined } from '@ant-design/icons';
import { AuthContext } from '../../utils/authContext';
import { NavLink, useNavigate } from 'react-router-dom';
import api from '../../utils/api';

export const AccountSettings = () => {
  const user = useSelector((store: RootState) => store.user.user);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const dispatch = useDispatch();
  const { getUserData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [form] = useForm();

  const handleSubmit = async () => {
    setIsLoading(true);
    const { first_name, last_name, email } = form.getFieldsValue();
    const body = { first_name, last_name, email };

    try {
      await api.put(`/api/v1/users/${user.user_id}`, body);
      await getUserData(user.email);
      navigate('/home');
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const handleUpload = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await api.post(
        `/api/v1/users/upload_photo/${user.user_id}`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (event) => {
            onProgress({ percent: (event.loaded / event.total) * 100 });
          },
        }
      );
      onSuccess(response.data);
      dispatch(setUser({ ...user, photo: response.data.photo }));
    } catch (err) {
      onError(err);
    }
  };

  const removePhoto = async () => {
    setIsLoading(true);
    try {
      await api.delete(`/api/v1/users/delete_photo/${user.user_id}`);
      dispatch(setUser({ ...user, photo: '' }));
      setFileList([]);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const defaultFileList = user.photo
    ? [
        {
          uid: '1',
          name: 'current.png',
          status: 'done',
          url: `${window.location.origin}/api/v1/users/photo/${user.user_id}`,
        },
      ]
    : [];

  useEffect(() => {
    if (user.photo) {
      setFileList(defaultFileList);
    } else {
      setFileList([]);
    }
  }, [user.photo]);

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList?.[0]?.response?.photo) {
      dispatch(setUser({ ...user, photo: newFileList[0].response.photo }));
    }
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const sharedInputStyle = { maxWidth: 512 };

  return (
    <Spin spinning={isLoading}>
      <main className="main bg-white">
        <section className="pb-[48px]">
          <div className="container-custom">
            <NavLink to="/home" className="flex items-center mb-3 pt-[32px]">
              <h1 className="font-semibold text-[32px] text-[#1D2E54]">Account Settings</h1>
            </NavLink>
            <div className="account-settings-tab w-full px-6 py-3">
              <Form
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ ...user }}
                onFinish={handleSubmit}
                colon={false}
              >
                <Form.Item
                  label="Photo"
                  tooltip="This will be displayed on your profile."
                >
                  <Upload
                    customRequest={handleUpload}
                    listType="picture-circle"
                    fileList={fileList}
                    onPreview={() => setPreviewOpen(true)}
                    onChange={handleChange}
                    onRemove={removePhoto}
                  >
                    {fileList.length >= 1 ? null : uploadButton}
                  </Upload>
                </Form.Item>
                <div className="border-b-[1px] h-[1px] mb-6"></div>
                <Form.Item
                  label="First Name"
                  name="first_name"
                  rules={[{ required: true, message: 'Please input your first name!' }]}
                >
                  <Input placeholder="First Name" style={sharedInputStyle} />
                </Form.Item>
                <Form.Item
                  label="Last Name"
                  name="last_name"
                  rules={[{ required: true, message: 'Please input your last name!' }]}
                >
                  <Input placeholder="Last Name" style={sharedInputStyle} />
                </Form.Item>
                <div className="border-b-[1px] h-[1px] mb-6"></div>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: 'Please input your email!' },
                    { type: 'email', message: 'Please input a valid email!' },
                  ]}
                >
                  <Input disabled placeholder="Your Email" style={sharedInputStyle} />
                </Form.Item>
                <div className="border-b-[1px] h-[1px] mb-6"></div>
                <Form.Item label="Organization Name" name={['organization', 'name']}>
                  <Input disabled style={sharedInputStyle} />
                </Form.Item>
                <div className="border-b-[1px] h-[1px] mb-6"></div>
                <Form.Item label="Subscription Plan" name="subscription_plan">
                  <Form.Item
                    name="subscription_plan"
                    style={{ height: 44, marginBottom: 0 }}
                  >
                    <Input disabled style={sharedInputStyle} />
                  </Form.Item>
                  <div
                    style={{
                      position: 'absolute',
                      top: 90,
                      right: 0,
                      left: 342,
                      gap: 8,
                      display: 'flex',
                    }}
                  >
                    <Button
                      size="large"
                      onClick={() => {
                        form.resetFields();
                        navigate('/home');
                      }}
                    >
                      Cancel
                    </Button>
                    <Button type="primary" size="large" htmlType="submit">
                      Save
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </section>
      </main>
    </Spin>
  );
};