//@ts-ignore
import videoSrc from 'assets/video/Narrative_Demo.mp4';
import thumbSrc from 'assets/images/DataScoop_Explainer.png'
import { Link } from 'react-router-dom';
import React, { useState, useRef } from 'react';

export const IntroSection: React.FC = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef<HTMLVideoElement | null>(null);

    const openSamplePDF = () => {
        window.open('/static/public/DataScoop_Weekly_Update_Sample.pdf', '_blank');
    };

    const togglePlay = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    return (
        <section id="home" className="bg-[#f7f8fa] lg:pt-[140px] pt-[60px] pb-[30px] lg:pb-[70px]">
          <div className="flex items-center flex-col justify-between gap-[32px] xl:flex-row xl:gap-[20px] container-custom">
            <div className="lg:pl-10 max-w-[712px]">
              <h1 className="font-semibold leading-[120%] mb-3 lg:mb-4 lg:text-[42px] text-[#151618] text-[32px]">
                KPI Deep-Dives <span className="text-[#445082]">Automated</span>
              </h1>
              <div className="leading-[130%] text-[16px] lg:text-[20px] text-[#45484C] lg:mb-[48px] mb-10">
                  Quick insights into why your KPIs go up or down
              </div>
              <div className="flex items-center gap-3 flex-col lg:flex-row lg:max-w-[unset] lg:mx-[unset] max-w-[344px] mb-4 mx-auto">
                <Link to="/book" className="flex items-center justify-center font-semibold leading-[140%] duration-300 ease-in-out rounded-xl text-center bg-[#445082] hover:bg-[#2b3c6b] text-[#ffffff] lg:h-[50px] lg:min-w-[180px] min-w-full p-3 text-[17px]">
                  Demo with Founder
                </Link>
                <button
                  onClick={openSamplePDF}
                  className="flex items-center justify-center font-semibold leading-[140%] duration-300 ease-in-out rounded-xl text-center bg-[#ffffff] hover:bg-[#f0f0f0] text-[#445082] border border-[#445082] lg:h-[50px] lg:min-w-[180px] min-w-full p-3 text-[17px]"
                >
                  View a Sample
                </button>
              </div>
            </div>
            <div className="w-full max-w-[477px] flex justify-center lg:justify-start lg:pr-10 relative">
              <video 
                ref={videoRef}
                className="w-[100%]" 
                src={videoSrc}
                controls
                playsInline
                onEnded={() => setIsPlaying(false)}
              >
                Your browser does not support the video tag.
              </video>
              {!isPlaying && (
                <div 
                  className="absolute inset-0 flex items-center justify-center cursor-pointer"
                  onClick={togglePlay}
                >
                  <img src={thumbSrc} alt="Video thumbnail" className="absolute inset-0 w-full h-full object-cover" />
                  <div className="relative z-10 bg-white bg-opacity-75 rounded-full p-4 w-16 h-16 flex items-center justify-center">
                    <div className="w-0 h-0 border-t-[10px] border-t-transparent border-l-[20px] border-l-[#445082] border-b-[10px] border-b-transparent ml-1"></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
    )
}