// @ts-nocheck
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { Link, useNavigate } from 'react-router-dom';
import { awsConfig } from '../../services/awsConfig';
import { useState, useEffect, useRef, useContext } from 'react';
import { DropdownArrowIcon, FullLogoIcon, MessageIcon, UserIcon } from '../../components/UI/svgIcons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setUser } from '../../store/userSlice';
import { setReports } from '../../store/reportsSlice';
import { AuthContext, clearAllData } from './../../utils/authContext';
import { Avatar, Image } from 'antd';
import { getFullApiLink } from 'index';
import { UserOutlined } from '@ant-design/icons';


export const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { handleLogout, userPool, currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const logOut = () => {
    if (currentUser) {
      currentUser.globalSignOut({
        onSuccess: () => {
          console.log("Global sign-out successful.");
          handleLogout(); // Centralized logout handling
        },
        onFailure: (error) => {
          console.error("Global sign-out failed:", error);
          handleLogout(); // Proceed with local logout even if global fails
        },
      });
    } else {
      handleLogout(); // Fallback for cases where no current user is available
    }
  };  

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside as EventListener);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside as EventListener);
    };
  }, []);

  const user = useSelector((store: RootState) => store.user.user)

  return (
    <header className="sticky top-0 bg-[#ffffff] z-[1000]" data-html2canvas-ignore>
      <div className="container-custom">
        <div className="items-center flex justify-between py-4">
          <Link to="/home" className="">
            <FullLogoIcon />
          </Link>
          <div className="items-center flex gap-[32px]">
            <Link
              to="/contact-us"
              className="items-center flex whitespace-nowrap font-semibold leading-[150%] px-[18px] py-[10px] text-[#445082] text-[16px]"
            >
              <MessageIcon iconClass="mr-2" />
              Contact Us
            </Link>
            <nav
              className="items-center flex justify-between w-full flex-wrap relative"
              data-twe-navbar-ref
            >
              <div className="items-center flex justify-between w-full flex-wrap px-3">
                <div
                  className={`relative border border-[#E6E6E7] h-[56px] min-w-[240px] px-[0px] ${isDropdownOpen ? 'rounded-t-2xl' : 'rounded-2xl'
                    }`}
                  id="user-dropdown-wrap"
                  data-twe-dropdown-ref
                  ref={dropdownRef}
                >
                  <div
                    className="items-center flex whitespace-nowrap active:text-black/80 dark:active:text-white/80 dark:focus:text-white/80 dark:hover:text-white/80 dark:text-white/60 duration-100 focus:text-black/80 h-full hover:ease-in-out hover:text-black/80 motion-reduce:transition-none text-black/60 transition cursor-pointer"
                    onClick={toggleDropdown}
                  >
                    <div className="flex items-center justify-between w-full px-3">
                      <div
                        style={{
                          width: 45,
                          height: 45,
                          borderRadius: 100,
                        }}
                      >
                        <Avatar
                          preview={false}
                          shape="circle"
                          size={45}
                          icon={<UserOutlined />}
                          src={user.photo ? getFullApiLink(user.photo) : ''}
                        />
                      </div>
                      <div className="text-[14px] font-semibold text-[#2C2E30] flex-grow text-center">
                        {/* @ts-ignore */}
                        {user.first_name} {user.last_name}
                      </div>
                      <span className="[&>svg]:w-5">
                        <DropdownArrowIcon />
                      </span>
                    </div>
                  </div>
                  <ul
                    className={`border-[#E6E6E7] border min-w-[240px] -ml-[1px] absolute bg-clip-padding bg-white dark:bg-surfa dark:bg-surface-dark transform transition-transform duration-300 ease-in-out ${isDropdownOpen ? 'scale-y-100 opacity-100' : 'scale-y-0 opacity-0'
                      } origin-top rounded-bl-2xl rounded-br-2xl`}
                  >
                    <li>
                      <Link
                        className="whitespace-nowrap text-[16px] active:bg-zinc-200/60 active:no-underline bg-transparent block dark:active:bg-neutral-800/25 dark:hover:bg-neutral-800/25 focus:outline-none font-medium hover:bg-zinc-200/60 px-[14px] py-4 text-[#1F2022] w-full border-[#E6E6E7] border-b"
                        to="/account-settings"
                        data-twe-dropdown-item-ref
                      >
                        Account settings
                      </Link>
                    </li>
                    <li>
                      <button
                        className="text-left whitespace-nowrap text-[16px] active:bg-zinc-200/60 active:no-underline bg-transparent block dark:active:bg-neutral-800/25 dark:hover:bg-neutral-800/25 focus:outline-none font-medium hover:bg-zinc-200/60 px-[14px] py-4 text-[#1F2022] w-full rounded-bl-2xl rounded-br-2xl"
                        onClick={logOut}
                        data-twe-dropdown-item-ref
                      >
                        Sign out
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};