import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setReportConfig, setReportName, setResult } from "../../store/reportsSlice";
import { Popconfirm, Spin, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { RootState } from "../../store";
import axios from "axios";
import dayjs from "dayjs";
import api from '../../utils/api';

interface HomeSavedItemProps {
  onRemoveClick: () => void;
  onSubscriptionChange: (reportId: number, newSubscriptionState: boolean) => void;
  name?: string;
  subscribed: boolean;
}

// @ts-ignore
export const HomeDatasetItem = ({ onRemoveClick, onSubscriptionChange, name, report, subscribed }: HomeSavedItemProps) => {
  const navigate = useNavigate()
  const user = useSelector((store: RootState) => store.user.user)
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const handleRemoveClick = async () => {
    setIsLoading(true)
    // @ts-ignore
    await api.delete(`/api/v1/reports/${report.report_id}`);
    setIsLoading(false)
    if (onRemoveClick) {
      onRemoveClick();
    }
  };
  const setupReport = async () => {
    setIsLoading(true)
    setIsLoading(false)
    dispatch(setReportConfig(JSON.parse(report.report_config)))
    // dispatch(setResult(JSON.parse(report.report_content)))
    dispatch(setReportName(report.report_name))
    navigate(`/report-setup?reportId=${report.report_id}`);
  }
  // @ts-ignore
  const handleSubscriptionChange = async (e) => {
    const newSubscriptionState = e.target.checked;
    setIsLoading(true);
  
    try {
      // Try to update existing record
      // @ts-ignore
      await api.put(`/api/v1/reports_users/${report.report_id}/${user.user_id}`, {
        subscribed: newSubscriptionState,
      });
    } catch (err) {
      // If record doesn't exist, create a new one
      // @ts-ignore
      if (err?.response?.status === 404) {
        await api.post('/api/v1/reports_users/', {
          report_id: report.report_id,
          subscribed: newSubscriptionState,
          // @ts-ignore
          user_id: user.user_id,
        });
      } else {
        // Handle other types of errors
        console.error("Error toggling subscription:", err);
      }
    } finally {
      setIsLoading(false);
      // Call the callback to refresh the home page
      onSubscriptionChange(report.report_id, newSubscriptionState);
    }
  }

  return (
    <Spin indicator={<LoadingOutlined spin />} size="large" spinning={isLoading}>
      <div className="flex justify-between items-center gap-6 py-6 border-b border-b-[#E6E6E7]">
        {/* <div className="flex items-center min-w-[150px]">
          <label className="flex items-center cursor-pointer">
            <input type="checkbox" checked={subscribed} className="peer sr-only" onChange={handleSubscriptionChange} />
            <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:bg-[#445082] after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border after:border-gray-300 after:h-5 after:w-5 after:rounded-full after:transition-all"></div>
          </label>
          <span className="ml-2 text-[14px] font-semibold text-[#35383B]">Subscribe</span>
          <Tooltip title="Subscribe to receive this analysis on email">
            <svg
              className="ml-1 cursor-help"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_504_9467)">
                <path
                  d="M6.06 6.00016C6.21674 5.55461 6.5261 5.1789 6.93331 4.93958C7.34051 4.70027 7.81927 4.61279 8.28479 4.69264C8.75031 4.77249 9.17255 5.01451 9.47672 5.37585C9.78089 5.73718 9.94737 6.19451 9.94667 6.66683C9.94667 8.00016 7.94667 8.66683 7.94667 8.66683M8 11.3335H8.00667M14.6667 8.00016C14.6667 11.6821 11.6819 14.6668 8 14.6668C4.3181 14.6668 1.33334 11.6821 1.33334 8.00016C1.33334 4.31826 4.3181 1.3335 8 1.3335C11.6819 1.3335 14.6667 4.31826 14.6667 8.00016Z"
                  stroke="#AAABAC"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_504_9467">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Tooltip>
        </div> */}

        <Tooltip title="Start a new analysis on this dataset">
          <button
            onClick={setupReport}
            className="text-center text-[14px] w-[300px] duration-300 ease-in-out rounded-[10px] border border-[#CACBCB] font-semibold hover:bg-[#F3F2FF] hover:border-[#AAABAC] px-4 py-[10px] text-[#2C2E30]"
          >
            {name || 'Discover Growth Segments'}
          </button>
        </Tooltip>

        <div className="text-[14px] min-w-[200px]">
          Last Refreshed: {report?.updated_at ? new Date(report?.updated_at).toLocaleDateString() : new Date(report?.created_at).toLocaleDateString()}
        </div>

        <div className="min-w-[100px]">
          <Popconfirm
            title="Delete the report"
            description="Are you sure that you want to delete this saved report?"
            onConfirm={handleRemoveClick}
            okText="Remove"
            cancelText="Cancel"
          >
            <Tooltip title="Only administrators of your organization can remove a dataset">
              <span>
                <button
                  className="text-[14px] duration-300 ease-in-out font-semibold text-[#445082] disabled:text-gray-400 disabled:cursor-not-allowed"
                  type="button"
                  disabled={true}
                >
                  Remove
                </button>
              </span>
            </Tooltip>
          </Popconfirm>
        </div>
      </div>
    </Spin>
  );
};
