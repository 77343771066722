// @ts-nocheck
import { ArrowRightIcon } from "../../../components/UI/svgIcons";
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../utils/authContext";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

interface OnboardingSecureStepProps {
  email: string;
  signUp: () => void;
  secureCode: string;
  setSecureCode: React.Dispatch<React.SetStateAction<string>>;
  nextStep: () => void;
  userPool: CognitoUserPool;
  password: string;
  backButton: JSX.Element;
  firstName?: string;
  lastName?: string;
}

export const OnboardingSecureStep = ({
  email,
  firstName,
  lastName,
  signUp,
  secureCode,
  setSecureCode,
  userPool,
  nextStep,
  password,
  backButton
}: OnboardingSecureStepProps) => {
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [error, setError] = useState("");
  const { setIsAuthenticated, setAuthData } = useContext(AuthContext);
  const { handleLogin, fetchSession } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    const userDataStr = localStorage.getItem("userData");
    if (userDataStr) {
      const userData = JSON.parse(userDataStr);
      console.log("Authenticated user data:", userData);
    } else {
      console.log("User data not found in localStorage");
    }
  }, []);

  const handleChange = (index: number, value: string) => {
    if (/^\d?$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
      const enteredCode = newCode.join("");
      console.log(`Current secure code: ${enteredCode}`);
      if (value && index < code.length - 1) {
        const nextInput = document.getElementById(`secure-num-${index + 1}`);
        nextInput?.focus();
      }
    }
  };

  const handleConfirmUser = () => {
    const enteredCode = code.join("");
    if (enteredCode.length === 6) {
      const userData = {
        Username: email,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);

      cognitoUser.confirmRegistration(enteredCode, true, (err, result) => {
        if (err) {
          console.error(
            "Error confirming user:",
            err.message || JSON.stringify(err)
          );
          setError("Invalid code. Please check your email and try again.");
        } else {
          console.log("User confirmed:", result);
          const authenticationData = {
            Username: email,
            Password: password,
          };
          const authenticationDetails = new AuthenticationDetails(
            authenticationData
          );

          const userData = {
            Username: email,
            Pool: userPool,
          };
          const cognitoUser = new CognitoUser(userData);

          cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: async (result) => {
              console.log("User authenticated:", result);
              const accessToken = result.getAccessToken().getJwtToken();
              await axios('/api/v1/users/', {
                method: 'POST',
                headers: {
                  "Authorization": `Bearer ${accessToken}`,
                  "Content-Type": "application/json",
                },
                data: JSON.stringify({
                  email,
                  username: email,
                  first_name: firstName,
                  last_name: lastName,
                }),
              })
              setIsAuthenticated(true);
              setAuthData({ accessToken, email });
              handleLogin(email);
              // fetchSession();
              navigate('/onboarding-page');
            },
            onFailure: (err) => {
              console.error("Authentication error:", err);
              setError("Login failed. Please try again.");
            },
          });
        }
      });
    } else {
      setError("Please enter the complete 6-digit code.");
    }
  };

  const resendCode = async () => {
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    try {
      await new Promise<void>((resolve, reject) => {
        cognitoUser.resendConfirmationCode((err) => {
          if (err) {
            console.error("Error resending code:", err);
            setError("Failed to resend code. Please try again.");
            reject(err);
          } else {
            console.log("Code resent successfully");
            resolve();
          }
        });
      });
    } catch (err) {
      console.error("An error occurred:", err);
      setError("An error occurred. Please try again later.");
    }
  };

  
  return (
    <div className="wrapper">
      <main className="flex-wrapper main">
        <section className="">
          <div className="flex items-center container-custom justify-center">
            <div style={{
              width: 'calc(100vw - 32px)'
            }} className="accent-block my-4 px-10 py-6 relative max-w-[560px]">
              {/* <button
                className="font-semibold text-[#2B3C6B] text-[14px] -left-[10px] flex items-center leading-[140%] relative"
                type="button"
              >
                <ArrowLeftIcon iconClass="mr-3"/>
                Back
              </button> */}
              {backButton}
              <div className="flex items-center flex-col mb-10">
                <svg
                  className="mb-6"
                  width="64"
                  height="64"
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M44.3078 19.6919V44.3073H19.6924L44.3078 19.6919Z"
                    fill="#1D2E54"
                  />
                  <path
                    d="M39.6924 24.3071V44.3071H19.6924L39.6924 24.3071Z"
                    fill="#445082"
                  />
                  <path
                    d="M32.0002 19.6919V44.3073H7.38477L32.0002 19.6919Z"
                    fill="#1D2E54"
                  />
                  <path
                    d="M27.3848 24.3071V44.3071H7.38477L27.3848 24.3071Z"
                    fill="#445082"
                  />
                  <path
                    d="M7.38477 56.615H56.6155L44.3078 44.3074H19.6925V19.692L7.38477 7.38428V56.615Z"
                    fill="#B0B5EF"
                  />
                </svg>
                <h1 className="accent-title mb-3 text-center">Welcome to DataScoop</h1>
                <div className="text-center max-w-[432px] text-[#45484C] text-[16px]">
                  Please, check out your Email
                  <span className="font-medium text-[#445082]"> {email} </span>
                  for the 6 digit code.
                </div>
              </div>
              <div className="mb-6">
                <div className="mb-[40px]">
                  <label className="font-medium inline-block mb-[6px] text-[#2C2E30] text-[14px]">
                    Secure Code
                  </label>
                  <div className="flex items-center gap-1">
                    {code.map((digit, index) => (
                      <input
                        key={index}
                        id={`secure-num-${index}`}
                        className="font-semibold text-[#2B3C6B] border border-[#AAABAC] h-[64px] leading-[120%] p-2 placeholder:text-[#AAABAC] rounded-xl secure-num text-[40px] text-center w-[77px]"
                        type="text"
                        maxLength={1}
                        value={digit}
                        onChange={(e) => handleChange(index, e.target.value)}
                      />
                    ))}
                  </div>
                </div>
                {error && (
                  <div className="text-red-500 text-center mb-6">{error}</div>
                )}
                <div className="flex justify-center mb-6">
                  <button
                    className="font-semibold text-[#2B3C6B] text-[14px] underline underline-offset-4"
                    type="button"
                    onClick={resendCode}
                  >
                    Resend the Code
                  </button>
                </div>
                <button
                  className="accent-btn w-full p-3"
                  type="button"
                  onClick={handleConfirmUser}
                >
                  Next
                  <ArrowRightIcon iconClass="ml-3" />
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};