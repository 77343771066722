import { Link } from "react-router-dom";
import { FullLogoIcon, LogoIcon, MessageIcon } from "../../components/UI/svgIcons";
import { IntroSection } from "./introSection";
import { SliderSection } from "./sliderSection";
import { ProblemSection } from "./problemSection";
import { SolutionSection } from "./solutionSection";
import { GettingStarted } from "./gettingStarted";
import { SecuritySection } from "./securitySection";
import { PricingSection } from "./pricingSection";
import { FormSection } from "./formSection";
import { TestimonialSection } from "./testimonials";
import { Footer } from "./footer";
import { useEffect, useRef, useState } from "react";
import { MarketSection } from "./markerSection";
import { UniqueValueSection } from "./uniqueValue";
import { BenefitSection } from "./benefitSection";
import { TrustSection } from "./trust";

export const WelcomePage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isMenuOpen && menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isMenuOpen]);

    const handleLinkClick = () => {
      setIsMenuOpen(false);
    };

  return (

    <div className="wrapper">
      {/* <!-- Header desktop --> */}
    <header className="bg-[#f7f8fa] fixed top-0 left-0 right-0 z-[10]">
        <div className="container-custom">
          <div className="flex items-center justify-between h-[80px] py-4">

            <a href="#home" className="flex items-center gap-[64px]">
              <FullLogoIcon />
            </a>

            <nav className="hidden xl:flex xl:items-center xl:gap-10">
              <ul className="flex items-center gap-10">
                <li>
                  <a href="#home" className="font-semibold text-[16px] leading-[150%] hover:text-[#1D2E54] text-[#2B3C6B] hover:text-[#69b1ff]">Home</a>
                </li>
                <li>
                  <a href="#problem" className="font-semibold text-[16px] leading-[150%] hover:text-[#1D2E54] text-[#2B3C6B] hover:text-[#69b1ff]">Problem</a>
                </li>
                <li>
                  <a href="#value" className="font-semibold text-[16px] leading-[150%] hover:text-[#1D2E54] text-[#2B3C6B] hover:text-[#69b1ff]">Solution</a>
                </li>
                <li>
                  <a href="#pricing" className="font-semibold text-[16px] leading-[150%] hover:text-[#1D2E54] text-[#2B3C6B] hover:text-[#69b1ff]">Pricing</a>
                </li>
                <li>
                  <a href="#testimonials" className="font-semibold text-[16px] leading-[150%] hover:text-[#1D2E54] text-[#2B3C6B] hover:text-[#69b1ff]">Testimonials</a>
                </li>
              </ul>
            </nav>

            <div className="flex items-center gap-[12px]">
              <Link to="/contact-us" className="hidden xl:flex items-center font-semibold leading-[150%] px-[18px] py-[10px] text-[#445082] text-[16px] whitespace-nowrap">
                <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.9166 15.0002L12.3809 10.0002M7.61904 10.0002L2.08335 15.0002M1.66666 5.8335L8.47076 10.5964C9.02173 10.982 9.29722 11.1749 9.59688 11.2496C9.86157 11.3156 10.1384 11.3156 10.4031 11.2496C10.7028 11.1749 10.9782 10.982 11.5292 10.5964L18.3333 5.8335M5.66666 16.6668H14.3333C15.7335 16.6668 16.4335 16.6668 16.9683 16.3943C17.4387 16.1547 17.8212 15.7722 18.0608 15.3018C18.3333 14.767 18.3333 14.067 18.3333 12.6668V7.3335C18.3333 5.93336 18.3333 5.2333 18.0608 4.69852C17.8212 4.22811 17.4387 3.84566 16.9683 3.60598C16.4335 3.3335 15.7335 3.3335 14.3333 3.3335H5.66666C4.26653 3.3335 3.56646 3.3335 3.03168 3.60598C2.56128 3.84566 2.17882 4.22811 1.93914 4.69852C1.66666 5.2333 1.66666 5.93336 1.66666 7.3335V12.6668C1.66666 14.067 1.66666 14.767 1.93914 15.3018C2.17882 15.7722 2.56128 16.1547 3.03168 16.3943C3.56646 16.6668 4.26653 16.6668 5.66666 16.6668Z" stroke="#2B3C6B" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                Contact Us
              </Link>
              <div className="hidden xl:flex items-center gap-[12px] xl:gap-[24px] btn-none">
                {/* <Link to="/login" className="font-semibold text-[16px] text-center duration-300 ease-in-out h-[44px] rounded-xl leading-[140%] py-[10px] border border-[#CACBCB] hover:bg-[#F3F2FF] hover:border-[#AAABAC] text-[#2C2E30] w-[120px]" type="button">
                  Sign in
                </Link>
                <Link to="/register" className="font-semibold text-[16px] text-center duration-300 ease-in-out h-[44px] rounded-xl leading-[140%] py-[10px] bg-[#445082] hover:bg-[#2b3c6b] text-[#ffffff] w-[120px]" type="button">
                  Sign up
                </Link> */}
                <Link to="/login" className="font-semibold text-[16px] text-center duration-300 ease-in-out h-[44px] rounded-xl leading-[140%] py-[10px] bg-[#445082] hover:bg-[#2b3c6b] text-[#ffffff] w-[120px]" type="button">
                  Sign in
                </Link>
              </div>
            </div>

            <div className="xl:hidden flex items-center relative">
              <button className="text-[#2C2E30] p-2 menu-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                </svg>
              </button>

              {isMenuOpen && (
                <div ref={menuRef} className="menu-wrapper fixed  inset-0 flex items-start justify-end bg-semi-black z-40" onClick={() => setIsMenuOpen(false)}>
                  <div className="relative bg-white shadow-lg w-[250px] py-4 ml-auto" onClick={(e) => e.stopPropagation()}>
                    <button className="absolute top-8 right-2 p-2" onClick={() => setIsMenuOpen(false)}>
                      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                    <div className="p-4">
                      <a href="#home" className="flex items-center gap-[64px] mb-4" onClick={handleLinkClick}>
                        <LogoIcon />
                      </a>
                      <ul className="space-y-4 text-right">
                        <li>
                          <a href="#home" onClick={handleLinkClick} className="block font-semibold text-[16px] text-[#2B3C6B] hover:text-[#69b1ff] p-[10px] text-left">Home</a>
                        </li>
                        <li>
                          <a href="#problem" onClick={handleLinkClick} className="block font-semibold text-[16px] text-[#2B3C6B] hover:text-[#69b1ff] p-[10px] text-left">Problem</a>
                        </li>
                        <li>
                          <a href="#value" onClick={handleLinkClick} className="block font-semibold text-[16px] text-[#2B3C6B] hover:text-[#69b1ff] p-[10px] text-left">Solution</a>
                        </li>
                        <li>
                          <a href="#pricing" onClick={handleLinkClick} className="block font-semibold text-[16px] text-[#2B3C6B] hover:text-[#69b1ff] p-[10px] text-left">Pricing</a>
                        </li>
                        <li>
                        <li>
                          <a href="#testimonials" onClick={handleLinkClick} className="block font-semibold text-[16px] text-[#2B3C6B] hover:text-[#69b1ff] p-[10px] text-left">Testimonials</a>
                        </li>
                        <br/>
                      <Link to="/contact-us" onClick={handleLinkClick} className="block font-semibold text-[16px] text-[#445082] hover:text-[#69b1ff] p-[10px] text-left">
                        Contact Us
                      </Link>
                    </li>
                      </ul>
                      <div className="flex gap-[12px] py-4">
                    {/* <div>
                      <Link to="/login" className="font-semibold text-[16px] text-center duration-300 ease-in-out h-[44px] rounded-xl leading-[140%] px-[20px] py-[10px] border border-[#CACBCB] hover:bg-[#F3F2FF] hover:border-[#AAABAC] text-[#2C2E30] " type="button">
                        Sign in
                      </Link>
                    </div>
                    <div>
                      <Link to="/register" className="font-semibold text-[16px] text-center duration-300 ease-in-out h-[44px] rounded-xl leading-[140%] px-[20px] py-[10px] bg-[#445082] hover:bg-[#2b3c6b] text-[#ffffff]" type="button">
                        Sign up
                      </Link>
                    </div> */}
                    <div>
                      <Link to="/login" className="font-semibold text-[16px] text-center duration-300 ease-in-out h-[44px] rounded-xl leading-[140%] px-[20px] py-[10px] bg-[#445082] hover:bg-[#2b3c6b] text-[#ffffff]" type="button">
                        Sign in
                      </Link>
                    </div>
                    </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>



      <main className="main mt-[64px] xl:mt-0">

        <IntroSection />
        <ProblemSection />
        {/* <SliderSection /> */}
        <UniqueValueSection />
        <SolutionSection />
        <TrustSection />
        <BenefitSection />
        <TestimonialSection />
        <GettingStarted />
        <SecuritySection />
        <PricingSection />
        <MarketSection />
        <FormSection />
      </main>
      <Footer />

    </div>
  );
}