// @ts-nocheck
import { Card, Divider, Flex, Title } from '@tremor/react';
import { useSelector } from 'react-redux';
import { CSVLink } from "react-csv";
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { RootState } from './../../store';
import { useEffect, useMemo, useState } from 'react';
import { Button, Drawer, Spin, Table, Tooltip as AntdTooltip } from 'antd';
import { ReportSearchIcon } from './../../components/UI/svgIcons';
import { FilterIcon } from './../../components/UI/svgIcons';
import { SegmentDetails } from './segmentDetails';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import api from './../../utils/api';

interface Props {
  waterfallRows: {
    key: string;
    change: number;
    changeWithNoOverlap: number;
  }[];
  totalImpact: number;
}

export function formatNumber(num: number, digits: number = 2) {
  if (Number.isInteger(num)) {
    return num.toLocaleString('en-US');
  }

  return num.toLocaleString('en-US', {
    style: "decimal",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
}

export function sortDimension(
  dimension1: {
    dimension: string;
    value: string;
  },
  dimension2: {
    dimension: string;
    value: string;
  }
): number {
  return dimension1.dimension.toLowerCase() > dimension2.dimension.toLowerCase()
    ? 1
    : -1;
}

export function serializeDimensionSliceKey(
  key: DimensionSliceKey,
  valueSplitter: string = ":",
  dimensionSplitter: string = "|"
): string {
  return [...key]
    .sort(sortDimension)
    .map((k) => `${k.dimension}${valueSplitter}${k.value}`)
    .join(dimensionSplitter);
}

const getColorAndArrow = (comparisonValue, baselineValue, isInverted) => {
  const isPositive = comparisonValue > baselineValue;

  if (!isInverted) {
    // If Increasing
    return isPositive
      ? { color: 'bg-green-200', arrow: <ArrowUpOutlined className="text-green-500" /> }
      : { color: 'bg-red-200', arrow: <ArrowDownOutlined className="text-red-500" /> };
  } else {
    // If Decreasing
    return isPositive
      ? { color: 'bg-red-200', arrow: <ArrowUpOutlined className="text-red-500" /> }
      : { color: 'bg-green-200', arrow: <ArrowDownOutlined className="text-green-500" /> };
  }
};

const CustomizedGroupTick = (props: any) => {
  const { x, y, payload } = props;
  const numberOfCharsPerRow = 15;
  const strParts = (payload.value as string).split(" ");
  // console.log('props', props)
  const rows: string[] = [];
  let row = "";
  strParts.forEach((strPart, idx) => {
    if (row.length + strPart.length < numberOfCharsPerRow) {
      row = `${row} ${strPart}`;
    } else {
      rows.push(row);
      if (strPart.length < numberOfCharsPerRow) {
        row = strPart;
      } else {
        if (strPart.at(strPart.length - 1) === ":") {
          row = `${strPart.substring(0, numberOfCharsPerRow - 4)}...:`;
        } else {
          row = row = strPart.substring(0, numberOfCharsPerRow - 3);
        }
      }
    }
    if (row.length > numberOfCharsPerRow || idx === strParts.length - 1) {
      rows.push(row);
      row = "";
    }
  });
  return (
    <g transform={`translate(${x},${y})`}>
      {rows.map((row, idx) => (
        <text
          x={0}
          y={0}
          dy={18 * (idx + 1)}
          textAnchor="middle"
          fill="#666"
          key={row}
        >
          {idx > 4 ? "..." : row}
        </text>
      ))}
    </g>
  );
};

function shortenNumber(value: number) {
  const number = Math.abs(value);
  if (number > 1000000000) {
    return formatNumber(value / 1000000000) + "B";
  } else if (number > 1000000) {
    return formatNumber(value / 1000000) + "M";
  } else if (number > 1000) {
    return formatNumber(value / 1000) + "K";
  } else {
    return formatNumber(value);
  }
}

export const useWaterfall = () => {
  const resultState = useSelector((store: RootState) => store.reports.result);
  const config = useSelector((store: RootState) => store.reports.report.config)
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [slices, setSlices] = useState([]);

  const getWaterfallKeys = () => {
    const result = resultState[Object.keys(resultState)[0]]

    const allTopDriverSlices = result.topDriverSliceKeys.map(key => result.dimensionSliceInfo[key]);
    console.log('fetch waterfall', allTopDriverSlices, result)
    const filteredTopDriverSlices = allTopDriverSlices.filter((slice) => {
      return slice.changeDev > 0.15;
    })
    return [filteredTopDriverSlices.map(slice => slice.key), filteredTopDriverSlices]
  }

  const fetchWaterfall = async () => {
    const [waterfallKeys, waterfallSlices] = getWaterfallKeys();

    const {
      baseDateRange,
      comparisonDateRange,
      dateColumn,
      fileId,
      filters,
      metricColumn,
    } = config.rawPayload

    setIsLoading(true)
    try {
      const response = await api.post('/api/v1/insight/file/waterfall-insight', {
        baseDateRange,
        comparisonDateRange,
        dateColumn,
        fileId,
        filters,
        metricColumn,
        segmentKeys: waterfallKeys,
      });
      const data = response.data;
      setData(data)
      setSlices(waterfallSlices)
      console.log(data)
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false);
    }
  }

  return ({
    fetchWaterfall,
    waterfallData: data,
    waterfallSlices: slices,
    waterfallLoading: isLoading,
  })
}


const commonTableProps = {
  pagination: false,
}

const expandedRowRender = (columns) => (record) => {

  return (
    <Table
      rowKey="serializedKey"
      showHeader={false}
      columns={columns}
      rowClassName={() => "expanded-row"}
      expandable={{ expandedRowRender: expandedRowRender(columns), rowExpandable: (record) => record.subSlices?.length, indentSize: 0, }}
      dataSource={record.subSlices}
      {...commonTableProps}
    />
  )
}

function relDiff(a, b) {
  return (((a - b) / b) * 100);
}


const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    const data = payload.find((p: any) => p.dataKey === "overlap").payload;
    const change = data.change + (data.flipped ? -data.overlap : data.overlap);
    return (
      <Flex
        className="border bg-white p-2"
        flexDirection="col"
        justifyContent="start"
        alignItems="start"
      >
        <Flex className="gap-2" justifyContent="start">
          <Flex className="gap-2">
            {(label as string).split(" AND ").flatMap((component, idx) => {
              const result = [
                <span
                  className="text-black border-2 bg-gray-100 p-1"
                  key={`${label}-${component}-value`}
                >
                  {component}
                </span>,
              ];

              if (idx < (label as string).split(" AND ").length - 1) {
                result.push(<span key={`${label}-${component}-and`}>AND</span>);
              }
              return result;
            })}
          </Flex>
        </Flex>
        <Divider className="my-3" />
        <p>Change: {formatNumber(change)}</p>
        <p>Change with overlap excluded: {formatNumber(data.change)}</p>
      </Flex>
    );
  }

  return null;
};

function WaterfallChart({ waterfallRows, totalImpact }: Props) {
  const filters = useSelector((store: RootState) => store.reports.report.config.settings);
  //@ts-ignore
  const isInverted = filters?.selectedMetricDirection === "decreasing";

  const [width, setWidth] = useState<number>(0);
  let pv = 0;
  const data = Object.values(waterfallRows)
    .slice(0, 8)
    .map((row, idx) => {
      let overlap = row.change - row.changeWithNoOverlap;
      let resultPv = pv - overlap;
      let flipped = false;
      if (
        (row.changeWithNoOverlap > 0 && overlap < 0) ||
        (row.changeWithNoOverlap < 0 && overlap > 0)
      ) {
        resultPv = pv + overlap;
        overlap = -overlap;
        flipped = true;
      }
      const result = {
        name: serializeDimensionSliceKey(row.key, ": ", " AND "),
        change: row.changeWithNoOverlap,
        totalImpact: row.change,
        pv: resultPv,
        pvForReferenceLine: pv,
        overlap,
        flipped,
      };
      pv = pv + row.changeWithNoOverlap;
      return result;
    });
  data.push({
    name: "All Others",
    change: totalImpact - pv,
    totalImpact: totalImpact - pv,
    pv,
    pvForReferenceLine: pv,
    overlap: 0,
    flipped: false,
  });
  data.push({
    name: "Total",
    change: totalImpact,
    totalImpact: totalImpact,
    pv: 0,
    pvForReferenceLine: 0,
    overlap: 0,
    flipped: false,
  });

  return (
    <ResponsiveContainer
      width="100%"
      height={450}
      onResize={(w) => setWidth(w)}
    >
      <BarChart
        width={1650}
        height={450}
        data={data}
        margin={{
          top: 50,
          right: 30,
          left: 20,
          bottom: -20,
        }}
      >
        <XAxis
          dataKey="name"
          tick={CustomizedGroupTick}
          height={120}
          interval={0}
          // interval={
          //   width === 0 ? 0 : Math.floor(waterfallRows.length / (width / 200))
          // }

          type="category"
        />
        <YAxis
          label={{ value: "Contribution", angle: -90, position: "insideLeft" }}
          padding={{
            bottom: 20,
          }}
          width={100}
          tickFormatter={shortenNumber}
        />
        <Tooltip content={<CustomTooltip />} />
        {data.map((row, idx) => {
          if (idx === data.length - 1) {
            return null;
          }
          return (
            <ReferenceLine
              segment={[
                {
                  x: data[idx].name,
                  y: data[idx].pvForReferenceLine + data[idx].change,
                },
                {
                  x: data[idx + 1].name,
                  y: data[idx].pvForReferenceLine + data[idx].change,
                },
              ]}
              stroke="gray"
              strokeDasharray="2 2"
              key={data[idx].name}
            />
          );
        })}
        <Bar dataKey="pv" stackId="a" fill="transparent" />
        <Bar dataKey="overlap" stackId="a">
          {data.map((item, index) => {
            if (
              (item.overlap < 0 && !item.flipped) ||
              (item.overlap > 0 && item.flipped)
            ) {
              return <Cell key={index} fill={isInverted ? "#abf7b1" : "#fec9c9"} />;
            } else if (
              (item.overlap > 0 && !item.flipped) ||
              (item.overlap < 0 && item.flipped)
            ) {
              return <Cell key={index} fill={isInverted ? "#fec9c9" : "#abf7b1"} />;
            }

            return <Cell key={index} fill="transparent" />;
          })}
        </Bar>
        <Bar dataKey="change" stackId="a" isAnimationActive={false}>
          <LabelList
            dataKey="totalImpact"
            position="top"
            formatter={(num: number) => {
              const prefix = num > 0 ? "+ " : "";
              const result = prefix + formatNumber(num);
              if (result.length * 8 > width / (waterfallRows.length + 2)) {
                return prefix + shortenNumber(num);
              }
              return result;
            }}
          />
          {data.map((item, index) => {
            if (item.name === "Total") {
              return <Cell key={index} fill="#3b82f6" />;
            }
            if (item.change < 0) {
              return <Cell key={index} fill={isInverted ? "#22c55e" : "#ef4444"} />;
            } else if (item.change > 0) {
              return <Cell key={index} fill={isInverted ? "#ef4444" : "#22c55e"} />;
            } else {
              return <Cell key={index} fill="#808080" />;
            }
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}


export const WaterfallTab = ({ deepFilters, formControl, goToOverview, isShown, waterfallProp, showAllTabs }) => {
  const [expandedDimensions, setExpandedDimensions] = useState({});
  const values = [];
  const result = useSelector((store: RootState) => store.reports.result) as ReportsState;
  const filters = useSelector((store: RootState) => store.reports.report.config.settings);
  const config = useSelector((store: RootState) => store.reports.report.config);
  const isInverted = filters?.selectedMetricDirection === "decreasing";
  const [drawerSlice, setDrawerSlice] = useState();
  const dynamicKey = Object.keys(result)[0];
  const rootPercentageDifference = result[dynamicKey].baselineValue !== 0 ? relDiff(result[dynamicKey].comparisonValue, result[dynamicKey].baselineValue) : 0;

  const { fetchWaterfall, waterfallData, waterfallSlices, waterfallLoading } = waterfallProp;

  const items = useMemo(() => waterfallSlices?.map((slice) => {
    return {
      key: slice.key,
      change: slice.impact,
      changeWithNoOverlap:
        waterfallData[slice.serializedKey].changeWithNoOverlap,
    };
  }) || [], [deepFilters, waterfallSlices, waterfallData]);

  const baselineValue = result[dynamicKey].baselineValue;
  const comparisonValue = result[dynamicKey].comparisonValue;

  const totalImpact = comparisonValue - baselineValue

  const [open, setOpen] = useState(false);

  const { hash } = useLocation();
  const navigate = useNavigate();

  // const openedSliceKey = hash && hash.length > 1 ? decodeURI(hash.slice(1)) : ''


  // useEffect(() => {
  //   if (openedSliceKey && !drawerSlice) {
  //     setDrawerSlice(Object.values(result[dynamicKey].dimensionSliceInfo).find((slice) => slice.serializedKey === openedSliceKey))
  //   }
  // }, [openedSliceKey])

  const csvHeaders = [
    { label: "Segment", key: "Segment" },
    { label: "Metric Value", key: "MetricValue" },
    { label: "Absolute Contribution", key: "AbsoluteContribution" },
    { label: "Relative Performance", key: "RelativePerformance" },
    { label: "Segment Size", key: "SegmentSize" },

  ];

  const processCsvRows = (slice) => {
    return ({
      Segment: slice.serializedKey,
      MetricValue: `${slice.baselineValue.sliceValue} vs ${slice.comparisonValue.sliceValue}`,
      AbsoluteContribution: slice.impact,
      RelativePerformance: Number(rootPercentageDifference - relDiff(slice.comparisonValue.sliceValue, slice.baselineValue.sliceValue)).toFixed(2),
      SegmentSize: `${slice.baselineValue.sliceSize} vs ${slice.comparisonValue.sliceSize}`
    })
  }

  const generateCsvData = (slicesArg) => [
    ...slicesArg.flatMap((slice) => {
      return [
        processCsvRows(slice),
        ...(slice?.subSlices ? slice?.subSlices?.flatMap?.(subSlice => [
          processCsvRows(subSlice),
          ...(subSlice?.subSlices ? subSlice?.subSlices?.flatMap?.(subSubSlice => [processCsvRows(subSubSlice)]) : [])
        ]) : [])
      ]
    })]

  const showDrawer = (slice) => () => {
    // navigate(`#${slice.serializedKey}`);
    setDrawerSlice(slice);
    setOpen(true);
  };

  const onClose = () => {
    setDrawerSlice();
    // navigate('#');
    setOpen(false);
  };

  const drawer = (
    <Drawer width={"100%"} mask={false}
      placement="left"
      closeIcon={false}
      close
      // extra={

      // }
      style={{
        top: 175,
        position: 'absolute',
        height: '-webkit-fill-available',
      }} onClose={onClose} open={drawerSlice}
      styles={{
        header: {
          display: 'flex',
          flexDirection: 'row-reverse',
        },
        body: {
          // overflow: 'unset',
        }
      }}
      rootStyle={{
        zIndex: 1000,
      }}
      title={(
        <div className="container-custom">
          <div className="flex justify-between items-center">
            <div className="flex gap-3">
              <h1 className="font-semibold text-[#1D2E54] text-[32px] mb-0">
                Segment Detail
              </h1>
              <div className="flex gap-1 items-center">
                {drawerSlice?.key?.map?.((item, i) => <>{i !== 0 && ' and '}
                  <span className="font-medium h-min px-2 py-[2px] bg-[#F3F2FF] inline-block rounded-2xl text-[#2B3C6B] text-[12px] mx-[2px]">
                    {item.dimension} = {item.value}
                  </span>
                </>)}
              </div>
            </div>
            <Button size="large" onClick={onClose}>Close Details</Button>
          </div>
          <div className="">
          </div>
        </div>
      )}>
      <SegmentDetails slice={drawerSlice} />
    </Drawer>
  )

  if (!isShown) return null;
  if (showAllTabs && !waterfallSlices?.length) return null;

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Segment', dataIndex: 'key', key: 'key', render: (value, record) => (
        <div className="flex gap-3 items-center justify-between">
          <div className="flex gap-3 items-center flex-wrap">
            {record.key.map((item, i) => (
              <>
                {i > 0 && <span className="font-medium">AND</span>}
                <AntdTooltip title={`${item.dimension}=${item.value}`} placement="top">
                  <span className="bg-indigo-200 py-1 px-2 rounded-lg segmentName">{item.dimension}={item.value}</span>
                </AntdTooltip>
              </>))}

          </div>
          <div className="flex gap-2 flex-shrink-0">
            <span onClick={() => {
              formControl.pushFilters(record.key, () => goToOverview());
            }}
              className="cursor-pointer hover:bg-gray-200 p-1 rounded"
            >
              <FilterIcon />
            </span>
            <span onClick={showDrawer(record)}
              className="cursor-pointer hover:bg-gray-200 p-1 rounded"
            >
              <ReportSearchIcon />
            </span>
          </div>
        </div>
      ),
      sortDirections: ['descend', 'ascend'],
      width: "32%",
      className: 'segment-name-row'
    },
    {
      title: 'Metric Value',
      dataIndex: 'baselineValue',
      key: 'baselineValue',
      render: (value, record) => (
        <>
          {shortenNumber(record.baselineValue.sliceValue, 2)}
          {' vs '}
          {shortenNumber(record.comparisonValue.sliceValue, 2)}
          <div className="bg-[#e6e6e6] inline py-1 px-2 ml-3 rounded-lg">
            {record.baselineValue.sliceValue ? Number(relDiff(record.comparisonValue.sliceValue, record.baselineValue.sliceValue)).toLocaleString('en-US', { maximumFractionDigits: 2 }) : 'N/A'}%
          </div>
        </>
      ),
      sortDirections: ['descend', 'ascend'],
      width: "22%"
    },
    {
      title: 'Absolute Contribution',
      dataIndex: 'impact',
      key: 'impact',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.impact - b.impact,
      render: (value, record) => {
        const { color, arrow } = getColorAndArrow(record.comparisonValue.sliceValue, record.baselineValue.sliceValue, isInverted);
        return (
          <div className={`${color} py-1 px-2 rounded-lg flex items-center inline-flex items-center gap-2`}>
            {arrow} {shortenNumber(value)}
          </div>
        );
      },
      width: "12%"
    },
    {
      title: 'Relative Performance',
      dataIndex: 'changePercentage',
      key: 'changePercentage',
      sortDirections: ['descend', 'ascend'],
      render: (value, record) => {
        const relativePerformance = record.baselineValue.sliceValue
          ? Number(relDiff(record.comparisonValue.sliceValue, record.baselineValue.sliceValue) - rootPercentageDifference).toFixed(2)
          : 'N/A';
        const { color, arrow } = getColorAndArrow(relativePerformance, 0, isInverted);
        return (
          <div className={`${color} py-1 px-2 rounded-lg inline-flex items-center gap-2`}>
            {arrow} {relativePerformance}%
          </div>
        );
      },
      sorter: (a, b) => a.changePercentage - b.changePercentage,
      width: "12%"
    },
    {
      title: 'Segment Size',
      dataIndex: 'comparisonValue',
      key: 'comparisonValue',
      render: (value, record) => (
        <>
          {(record.baselineValue.sliceSize * 100).toLocaleString('en-US', { maximumFractionDigits: 2 })}%
          {' vs '}
          {(record.comparisonValue.sliceSize * 100).toLocaleString('en-US', { maximumFractionDigits: 2 })}%
          <div className="bg-[#e6e6e6] inline py-1 px-2 ml-3 rounded-lg">
            {record.baselineValue.sliceSize ? relDiff(record.comparisonValue.sliceSize, record.baselineValue.sliceSize).toLocaleString('en-US', { maximumFractionDigits: 2 }) : 'N/A'}%
          </div>
        </>
      ),
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => (a.comparisonValue.sliceSize - a.baselineValue.sliceSize) - (b.comparisonValue.sliceSize - b.baselineValue.sliceSize),
      width: "22%"
    },
  ];

  return (
    <Spin spinning={waterfallLoading}>
      {/* <!--Tabs content--> */}
      <div className="" style={drawerSlice ? {
        overflowY: 'hidden',
        maxHeight: 100,
      } : {}}>
        <div className="w-full border shadow-md border-[#E6E6E7] rounded-[28px] py-6 mb-10 ">
          <div className="flex items-center justify-between gap-4 px-6 flex-col">
            <div className="flex-col gap-2 w-full">
              <h3 className="font-semibold text-[#1D2E54] leading-[130%] mb-1 text-[20px]">Waterfall chart</h3>
              <div className="text-[#828385] text-[12px]">
                Showing segment’s contribution with overlap excluded
              </div>
            </div>            <WaterfallChart
              waterfallRows={items}
              totalImpact={totalImpact}
            />
          </div>
        </div>
        {/* <div className="w-full relative border border-[#E6E6E7] mb-10 p-6 rounded-[28px] shadow-md">
                <div className="mb-6">
                  <h3 className="font-semibold text-[#1D2E54] leading-[130%] mb-1 text-[20px]">Waterfall chart</h3>
                  <div className="text-[#828385] text-[12px]">
                    Showing segment’s contribution with overlap excluded
                  </div>
                </div>
                <div className="font-medium text-[12px] -rotate-90 absolute bottom-[225px] left-0 text-[#35383B]">
                  Contribution
                </div>
                <canvas className="max-h-[384px] pl-[34px]" id="waterfallChart"></canvas>
              </div> */}
        {/* <!-- Table --> */}
        <div className="w-full border shadow-md border-[#E6E6E7] rounded-[28px] py-6">
          <div className="flex items-center justify-between gap-4 mb-6 px-6">
            <div className="font-semibold leading-[130%] text-[20px] text-[#2B3C6B]">Segments in chart</div>
            <div className="flex items-center">
              <div className="text-[#35383B] leading-[150%] text-[16px] border-r border-r-[#E6E6E7] px-4 py-2">
                Showing {expandedDimensions.allDrivers ? waterfallSlices.length : Math.min(8, waterfallSlices.length)} of {waterfallSlices.length} segments
              </div>
              <button onClick={() => {
                if (expandedDimensions.allDrivers) {
                  setExpandedDimensions(old => ({ ...old, allDrivers: false }))
                } else {
                  setExpandedDimensions(old => ({ ...old, allDrivers: true }))
                }
              }} className="text-[14px] leading-[140%] font-semibold hover:text-[#1D2E54] text-[#445082] border-r border-r-[#E6E6E7] px-[50px] py-[10px]" type="button">
                {expandedDimensions.allDrivers ? 'Collapse' : 'Show All'}
              </button>
              <button className="flex items-center text-[14px] justify-center duration-300 ease-in-out text-[#2B3C6B] bg-[#F3F2FF] font-semibold h-[40px] hover:bg-[#D5D6FD] hover:text-[#1D2E54] leading-[150%] ml-4 px-[17px] py-[10px] rounded-xl" type="button">
                <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5" stroke="#2B3C6B" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <CSVLink headers={csvHeaders} data={generateCsvData(waterfallSlices)} filename={"datascoop.csv"}>Export as CSV</CSVLink>
              </button>
            </div>
          </div>
          <div className="flex flex-col mb-[32px] overflow-hidden">
            <div className="lg:-mx-8 overflow-x-auto sm:-mx-6">
              <div className="inline-block lg:px-8 min-w-full py-2 sm:px-6">
                <div className="border-[#E6E6E7] border overflow-hidden">
                  <Table
                    rowKey="serializedKey"
                    columns={columns}
                    rowClassName={() => "root-row"}
                    expandable={{ expandedRowRender: expandedRowRender(columns), rowExpandable: (record) => record.subSlices?.length, indentSize: 0, }}
                    dataSource={expandedDimensions.allDrivers ? waterfallSlices : waterfallSlices.slice(0, 8)}
                    {...commonTableProps}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {drawer}
    </Spin>
  )
}