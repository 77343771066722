import { useContext, useState } from "react";
import { UserDataStep } from "./userDataStep";
import { OnboardingSecureStep } from "./onboardingSecureStep";
import {
  CognitoUserAttribute,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { BackStepButton } from "../../../components/auth-components/backStepBtn";
import { AuthContext } from "../../../utils/authContext";
import { awsConfig } from "../../../services/awsConfig";

export enum StepsRegEnum {
  userData = "userData",
  onboardingSecure = "onboardingSecure",
  onboardingSteps = "onboardingSteps",
}

const userPool = new CognitoUserPool({
  UserPoolId: awsConfig.userPoolId,
  ClientId: awsConfig.clientId,
});

export const RegisterPage = () => {
  const { handleLogin, fetchSession, signUp } = useContext(AuthContext);
  const [currentStep, setCurrentStep] = useState(StepsRegEnum.userData);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [secureCode, setSecureCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const checkIfUserExists = async (email: string) => {
    try {
      const response = await fetch('/api/v1/users/check-exists', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email })
      });

      if (!response.ok) {
        throw new Error('Failed to check user existence');
      }

      const data = await response.json();
      return data.exists;
    } catch (error) {
      console.error('Error checking if user exists:', error);
      throw new Error('Error checking user existence');
    }
  };

  const handleNext = async () => {
    try {
      const userExists = await checkIfUserExists(email);
      if (userExists) {
        setErrorMessage("The email already exists in the database, please sign in");
        return;
      }
      signUp(email, password, () => setCurrentStep(StepsRegEnum.onboardingSecure));
    } catch (err) {
      console.error("Error checking if user exists:", err);
      setErrorMessage("Error checking if user exists.");
    }
  };

  const handlePrevStep = () => {
    if (currentStep === StepsRegEnum.onboardingSecure) {
      setCurrentStep(StepsRegEnum.userData);
    }
  };

  return (
    <>
      {currentStep === StepsRegEnum.userData && (
        <UserDataStep
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          nextStep={handleNext}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}
      {currentStep === StepsRegEnum.onboardingSecure && (
        <OnboardingSecureStep
          firstName={firstName}
          lastName={lastName}
          email={email}
          signUp={() => signUp(email, password, () => setCurrentStep(StepsRegEnum.onboardingSecure))}
          secureCode={secureCode}
          setSecureCode={setSecureCode}
          nextStep={() => setCurrentStep(StepsRegEnum.onboardingSteps)}
          userPool={userPool}
          password={password}
          backButton={<BackStepButton prevStep={handlePrevStep} />}
        />
      )}
    </>
  );
};