// utils/isAuthenticated.tsx
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from './authContext';
import { isTokenExpired, refreshCognitoToken } from './tokenUtils';

export const useAuthenticated = () => {
  const { isAuthenticated, handleLogout } = useContext(AuthContext);
  const [isValid, setIsValid] = useState(isAuthenticated);
  const location = useLocation();

  useEffect(() => {
    const validateSession = async () => {
      const tokens = JSON.parse(localStorage.getItem('tokens') || '{}');
      
      if (!tokens.accessToken) {
        handleLogout();
        setIsValid(false);
        return;
      }

      if (isTokenExpired(tokens.accessToken)) {
        const { success } = await refreshCognitoToken();
        if (!success) {
          handleLogout();
          setIsValid(false);
        } else {
          setIsValid(isAuthenticated);
        }
      } else {
        setIsValid(isAuthenticated);
      }
    };

    validateSession();
    const intervalId = setInterval(validateSession, 60000);

    return () => clearInterval(intervalId);
  }, [isAuthenticated, handleLogout, location]);

  return isValid;
};