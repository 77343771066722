// utils/tokenUtils.ts
import { CognitoUserPool, CognitoRefreshToken } from "amazon-cognito-identity-js";
import { awsConfig } from "../services/awsConfig";

const userPool = new CognitoUserPool({
  UserPoolId: awsConfig.userPoolId,
  ClientId: awsConfig.clientId,
});

export const isTokenExpired = (token: string): boolean => {
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return Date.now() >= (payload.exp * 1000) - 30000; // 30-second buffer
  } catch {
    return true;
  }
};

export const refreshCognitoToken = async (): Promise<{
  success: boolean;
  accessToken?: string;
}> => {
  try {
    const tokens = JSON.parse(localStorage.getItem('tokens') || '{}');
    if (!tokens.refreshToken) return { success: false };

    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) return { success: false };

    return new Promise((resolve) => {
      // Create a CognitoRefreshToken object from the string token
      const refreshToken = new CognitoRefreshToken({ 
        RefreshToken: tokens.refreshToken 
      });

      cognitoUser.refreshSession(refreshToken, (err, session) => {
        if (err) {
          console.error('Token refresh failed:', err);
          resolve({ success: false });
          return;
        }

        const newTokens = {
          accessToken: session.getAccessToken().getJwtToken(),
          idToken: session.getIdToken().getJwtToken(),
          refreshToken: session.getRefreshToken().getToken()
        };

        localStorage.setItem('tokens', JSON.stringify(newTokens));
        resolve({ success: true, accessToken: newTokens.accessToken });
      });
    });
  } catch (error) {
    console.error('Token refresh error:', error);
    return { success: false };
  }
};