// @ts-nocheck
import React, { createContext, useState, ReactNode, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { awsConfig } from "../services/awsConfig";
import { CognitoUserAttribute, CognitoUserPool, CognitoUserSession, CognitoUser } from "amazon-cognito-identity-js";
import { setReports } from "../store/reportsSlice";
import { setUser } from "../store/userSlice";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Spin } from "antd";
import api from "../utils/api";

const cachedRoutes = [
  '/',
  '/home',
  '/discover-segments',
  '/onboarding-page',
];

const publicRoutes = [ 
  '/', 
  '/reset-password'
];

export const clearAllData = () => {
  sessionStorage.clear();
  localStorage.clear();

  caches.keys().then(keys => {
    keys.forEach(key => caches.delete(key));
  });

  indexedDB.databases().then(dbs => {
    dbs.forEach(db => indexedDB.deleteDatabase(db.name));
  });

  document.cookie = document.cookie.split(';').reduce((newCookie, keyVal) => {
    const pair = keyVal.trim().split('=');
    if (pair[0] && pair[0] !== 'path' && pair[0] !== 'expires') {
      newCookie += `${pair[0]}=;`;
    }
    return newCookie;
  }, 'expires=Thu, 01 Jan 1970 00:00:00 UTC; path:/;');
};

interface AuthContextType {
  isAuthenticated: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  authData: any;
  setAuthData: React.Dispatch<React.SetStateAction<any>>;
  handleLogin: () => void;
  handleLogout: () => void;
  fetchSession: () => void;
  userPool: CognitoUserPool | null;
  getUserData: (email?: string) => Promise<void>;
  signUp: (email: string, password: string, cb?: () => void) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  setIsAuthenticated: () => { },
  authData: null,
  setAuthData: () => { },
  handleLogin: () => { },
  handleLogout: () => { },
  fetchSession: () => { },
  userPool: null,
  getUserData: async () => { },
  signUp: () => { },
});

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [authData, setAuthData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [emailState, setEmailState] = useState('');
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userPool, setUserPool] = useState<CognitoUserPool | null>(null);
  const [currentUser, setCurrentUser] = useState<CognitoUser | null>(null);
  const isFetchingUser = useRef(false);

  const logError = (context: string, error: any) => {
    console.error(`[${context}]: ${error.message || error}`);
  };

  const handleLogin = (emailArg: string) => {
    setIsAuthenticated(true);
    getUserData(emailArg || emailState);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setAuthData(null);
    clearAllData();
    navigate('/login');
  };

  const getUserData = async (email?: string) => {
    setIsLoading(true);
    try {
      const response = await api.get(`/api/v1/users/details/${email || emailState}`);
      const data = response.data;
      dispatch(setUser(data));
      dispatch(setReports(data.reports_created));
    } catch (error) {
      logError("getUserData", error);
    } finally {
      setIsLoading(false);
      isFetchingUser.current = false;
    }
  };

  const fetchSession = () => {
    const pool = userPool || new CognitoUserPool({
      UserPoolId: awsConfig.userPoolId,
      ClientId: awsConfig.clientId,
    });

    if (!userPool) setUserPool(pool);

    const cognitoUser = currentUser || pool?.getCurrentUser();
    if (!cognitoUser) {
      setIsAuthenticated(false);
      if (publicRoutes.includes(location.pathname)) return;
      navigate('/login');
      return;
    }

    cognitoUser.getSession((err, session) => {
      if (err) {
        logError("fetchSession", err);
        setIsAuthenticated(false);
        if (publicRoutes.includes(location.pathname)) return;
        navigate('/login', { state: { message: "Your session has expired. Please log in again." } });
        return;
      }

      if (session.isValid()) {
        console.log("Session is valid");
        setIsAuthenticated(true);
        saveTokensToLocalStorage(session);
        fetchUserDetails(cognitoUser);
      } else {
        console.log("Session is invalid");
        setIsAuthenticated(false);
        if (publicRoutes.includes(location.pathname)) return;
        navigate('/login', { state: { message: "Your session has expired. Please log in again." } });
      }
    });
  };

  const saveTokensToLocalStorage = (session: CognitoUserSession) => {
    const tokens = {
      accessToken: session.getAccessToken().getJwtToken(),
      idToken: session.getIdToken().getJwtToken(),
      refreshToken: session.getRefreshToken().getToken(),
    };
    localStorage.setItem('tokens', JSON.stringify(tokens));
  };

  const fetchUserDetails = (cognitoUser: CognitoUser) => {
    if (publicRoutes.includes(location.pathname)) return;

    cognitoUser.getUserAttributes((err, attributes) => {
      if (err) {
        logError("fetchUserDetails", err);
        return;
      }

      const emailAttr = attributes?.find(attr => attr.getName() === "email");
      if (!emailAttr) {
        console.warn("Email attribute not found for user");
        return;
      }

      const email = emailAttr.getValue();
      setEmailState(email);

      if (!isFetchingUser.current) {
        isFetchingUser.current = true;
        getUserData(email).then(() => {
          const requestedRoute = searchParams.get("requested");
          const nextUrl = location.pathname === '/login'
            ? '/home'
            : requestedRoute && cachedRoutes.includes(requestedRoute)
              ? requestedRoute
              : `${location.pathname}${location.search}`;
          navigate(nextUrl);
        });
      }
    });
  };

  const signUp = (email: string, password: string, cb = () => { }) => {
    const pool = userPool || new CognitoUserPool({
      UserPoolId: awsConfig.userPoolId,
      ClientId: awsConfig.clientId,
    });

    if (!userPool) setUserPool(pool);

    const attributeList = [new CognitoUserAttribute({ Name: "email", Value: email })];

    pool.signUp(email, password, attributeList, [], (err, result) => {
      if (err) {
        logError("signUp", err);
        return;
      }
      const cognitoUser = result?.user;
      setCurrentUser(cognitoUser);
      console.log("User signed up:", cognitoUser?.getUsername());
      cb();
    });
  };

  useEffect(() => {
    fetchSession();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        userPool,
        isAuthenticated,
        setIsAuthenticated,
        authData,
        setAuthData,
        handleLogin,
        handleLogout,
        fetchSession,
        currentUser,
        getUserData,
        signUp,
      }}
    >
      <Spin spinning={isLoading}>
        {children}
      </Spin>
    </AuthContext.Provider>
  );
};